<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Partners</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Partners</h2>
                </div>
                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Approved</small>
                                    <h4>{{ this.riderOverview['approvedCount'] | number: '1.0-0' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/approved.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>For Review</small>
                                    <h4>{{ this.riderOverview['forReviewCount'] | number: '1.0-0' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/verification.svg">
                        </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>For Amendment</small>
                                    <h4>{{ this.riderOverview['forAmmendmentCount'] | number: '1.0-0' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/amendment.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Initial Registration</small>
                                    <h4>{{ this.riderOverview['initialReviewCount'] | number: '1.0-0' }}</h4>   
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/resubmission.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-multiple">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card-multiple-block">
                                <div class="card-multiple-item">
                                    <img width="60" src="../../../../assets/okito/images/card-icons/riders.svg">
                                    <div class="card-content">
                                        <small>2-Wheels</small>
                                        <h4>{{ this.riderOverview['totalApprovedTwowheels'] | number: '1.0-0'}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 border-left">
                            <div class="card-multiple-inline">
                                <div class="card-inline-item-2">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                    <small>Ok! Express</small>
                                        <h4>{{ this.riderOverview['rideExpressTwoWheelsCount'] | number: '1.0-0'}}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item-2">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Ok! MotoTaxi</small>
                                        <h4>{{ this.riderOverview['rideExpressFourWheelsCount'] | number: '1.0-0' }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-multiple">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card-multiple-block">
                                <div class="card-multiple-item">
                                    <img width="60" src="../../../../assets/okito/images/card-icons/supply.svg">
                                    <div class="card-content">
                                        <small>4-Wheels</small>
                                        <h4>{{ this.riderOverview['totalApprovedFourWheels'] | number: '1.0-0'}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 border-left">
                            <div class="card-multiple-inline">
                                <div class="card-inline-item-2">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Ok! Express</small>
                                        <h4>{{ this.riderOverview['rideExpressFourWheelsCount'] | number: '1.0-0'}}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item-2">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Ok! Ride</small>
                                        <h4>{{ this.riderOverview['rideHailingFourWheelsCount'] | number: '1.0-0'}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div>
                        <div class="list-holder" style="margin-top: 2em;">
                            <div class="tools">
                                <div class="search mg-right-auto">
                                    <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                    <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                        placeholder="Search">
                                </div>
                                <div class="search mg-right-auto" style="width:58%;">
                                    <span style="font-weight:bold;margin-right:5px;">Sorting: </span>
                                    <select class="form-select" style="width:30%;margin-right:10px;" 
                                            [(ngModel)]="partnerSortBy" 
                                            (ngModelChange)="onSortChange()">
                                        <option value="firstName">First Name</option>
                                        <option value="middleName">Middle Name</option>
                                        <option value="lastName">Last Name</option>
                                        <option value="partnerId">Partner Id</option>
                                        <option value="dateCreated">Date Created</option>
                                        <option value="status">Status</option>
                                        <option value="earnings">Earnings</option>
                                    </select>
                                
                                    <div class="btn-group" role="group" aria-label="Sort by.." style="width:5%;">
                                        <button type="button" 
                                                [ngClass]="{'active': partnerSortDirection === 'asc'}" 
                                                class="btn btn-secondary"
                                                (click)="partnerSortDirection = 'asc'; onSortChange()">Ascending</button>
                                        <button type="button" 
                                                [ngClass]="{'active': partnerSortDirection === 'desc'}" 
                                                class="btn btn-secondary"
                                                (click)="partnerSortDirection = 'desc'; onSortChange()">Descending</button>
                                    </div>
                                </div>
                                <div class="actions">
                                    <button class="btn btn-sm btn-default">Download</button>
                                </div>
                            </div>
                            <div class="list-tabs">
                                <ul>
                                    <li [ngClass]="{'active': this.partnerTabs['FOR_ALL']}"><a (click)="filterUser('FOR_ALL')" style="cursor: pointer;">All</a></li>
                                    <li [ngClass]="{'active': this.partnerTabs['APPROVED']}"><a (click)="filterUser('APPROVED')" style="cursor: pointer;">Approved</a></li>
                                    <li [ngClass]="{'active': this.partnerTabs['FOR_REVIEW']}"><a (click)="filterUser('FOR_REVIEW')" style="cursor: pointer;">For Review</a></li>
                                    <li [ngClass]="{'active': this.partnerTabs['FOR_AMMENDMENT']}"><a (click)="filterUser('FOR_AMMENDMENT')" style="cursor: pointer;">For Ammendment</a></li>
                                    <li [ngClass]="{'active': this.partnerTabs['INITIAL_REGISTERED']}"><a (click)="filterUser('INITIAL_REGISTERED')" style="cursor: pointer;">Initial Registered</a></li>
                                    <!-- <li [ngClass]="{'active': this.partnerTabs['APPROVED']}"><a (click)="filterUser('APPROVED')" style="cursor: pointer;">Approved</a></li> -->
                                </ul>
                            </div>
                            <div class="list table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <!-- <th>Partner ID</th> -->
                                            <th>Name</th>
                                            <th>Date</th>
                                            <!-- <th>Time of Submission</th> -->
                                            <th>Vehicle Type</th>
                                            <th>Service Type</th>
                                            <th>Current Pts</th>
                                            <!-- <th>Used Pts</th> -->
                                            <!-- <th>Total Pts</th> -->
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecords">
                                            <tr *ngFor="let rider of this.currentSelectedPageList">
                                                <!-- <td>{{ rider.membershipId }}</td> -->
                                                <td>{{ rider.firstName + ' ' + rider.middleName + ' ' + rider.lastName}}</td>
                                                <td>{{ rider.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD' }}</td>
                                                <!-- <td>{{ rider.dateCreated | amFromUtc | amLocal | amDateFormat: 'h:mm:ss A' }}</td> -->
                                                <td>{{ rider.vehicleType }}</td>
                                                <td *ngIf="rider.partnerVehicles.length != 0">
                                                    <span *ngIf="rider.partnerVehicles[0].isExpress" class='stat stat-approved'>Express</span>&nbsp;
                                                    <span *ngIf="rider.partnerVehicles[0].isMototaxi" class='stat stat-approved'>Mototaxi</span>&nbsp;
                                                    <span *ngIf="rider.partnerVehicles[0].isOkRide" class='stat stat-approved'>OK Ride</span>&nbsp;
                                                </td>
                                                <td *ngIf="rider.partnerVehicles.length == 0">
                                                    <span class='stat stat-pending'>No Vehicle Data</span>&nbsp;
                                                </td>
                                                
                                                <td>{{ rider.currentPoints | number: '1.2-2'}}</td>
                                                <!-- <td>{{ rider.usedPoints | number: '1.2-2' }}</td> -->
                                                <!-- <td>{{ rider.usedPoints + rider.currentPoints | number: '1.2-2' }}</td> -->
                                                <td *ngIf="this.partnerTabs['verified']; else showOtherTabStatus">
                                                    <span class='stat stat-active'>Verified</span>
                                                </td>
                                                <ng-template #showOtherTabStatus>
                                                    <td>
                                                        <!-- <span class='stat stat-active' *ngIf="rider.status == 'for_approval'">For Approval</span>
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_ammendment'">For Ammendment</span>
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_resubmission'">For Resubmission</span>
                                                        <span class='stat stat-approved' *ngIf="rider.status == 'verified'">Verified</span>
                                                        <span class='stat stat-approved' *ngIf="rider.status == 'approved'">Approved</span> -->
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_verification'">For Verification</span>
                                                        <span class='stat stat-pending' *ngIf="rider.status == 'initial_registered'">Initial Registered</span>
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_approval'">For Approval</span>
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_review'">For Review</span>
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_additional_document_submission'">For Additional Document Submission</span>
                                                        <span class='stat stat-amend' *ngIf="rider.status == 'for_ammendment'">For Ammendment</span>
                                                        <span class='stat stat-approved' *ngIf="rider.status == 'approved'">Approved</span>

                                                        
                                                    </td>
                                                </ng-template>
                                                <td>
                                                    <button *ngIf="rider.status == 'approved'" (click)="manualTopUp(rider.partnerId, topupForm)"
                                                        class="btn btn-sm btn-outline" style="margin-right: 5px;">Top Up</button>
                                                    <button (click)="viewDetails(rider)" class="btn btn-sm btn-outline">View</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #noRecords>
                                            <tr>
                                                <td colspan="10" class=""no-records-msg>
                                                    <h6 class="ponds-contents-med-bold">No records found</h6>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="list-footer" *ngIf="this.riderList.length != 0">
                                <div class="pager">
                                    <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                            src="../../../../assets/okito/images/icons/prev.svg"></button>
                                    
                                    <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                            src="../../../../assets/okito/images/icons/next.svg"></button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>

    <ng-template #showDetailComponent>
        <div class="main-holder">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><a (click)="this.returnToList()" style="cursor: pointer;">Partners</a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>Partner Details</span></li>
                        </ol>
                    </div>
                    <div class="profile-holder">
                        <div class="profile">
                            <div class="pfp">
                                <img src="../../../../assets/okito/images/avatar.png">
                            </div>
                            <div class="profile-info">
                                <h4>{{ this.detailViewed['firstName'] + ' ' + this.detailViewed['middleName'] + ' ' + this.detailViewed['lastName'] }}</h4>
                                <div class="tags">
                                    <!-- <span class="stat stat-active">Active</span> -->
                                    <span class="stat-icon" *ngIf="this.detailViewed['isApprove']; else showNotVerified"><img src="../../../../assets/okito/images/badge/verified.svg">Approved</span>
                                    <ng-template #showNotVerified>
                                        <span class="stat-icon"><img src="../../../../assets/okito/images/badge/not-verified.svg">Not Approved Yet</span>
                                    </ng-template>
                                    <span class="stat-icon"><img src="../../../../assets/okito/images/badge/vehicle.svg">{{ this.detailViewed['vehicleType'] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="profile-actions" *ngIf="this.enableEdit; else disableEdit">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="disableEditForm()">Cancel Edit</button>
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="updateThisDetails()">Save Details</button>
                        </div>

                        <ng-template #disableEdit>
                            <div class="profile-actions">
                                <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="enableEditForm()">Edit Partner</button>
                                <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                            </div>
                        </ng-template>
                    </div>
                    <div class="list-tabs">
                        <ul>
                            <li [ngClass]="{'active': this.detailTabs['personal']}"><a (click)="setTabPanelActive('personal')" style="cursor: pointer;">Personal Information</a></li>
                            <li [ngClass]="{'active': this.detailTabs['service_details']}"><a (click)="setTabPanelActive('service_details')" style="cursor: pointer;">Service Details</a></li>
                            <li [ngClass]="{'active': this.detailTabs['documents']}"><a (click)="setTabPanelActive('documents')" style="cursor: pointer;">Documents</a></li>
                            <li [ngClass]="{'active': this.detailTabs['transactions']}"><a (click)="setTabPanelActive('transactions')" style="cursor: pointer;">Transactions</a></li>
                            <li [ngClass]="{'active': this.detailTabs['topups']}"><a (click)="setTabPanelActive('topups')"
                                    style="cursor: pointer;">Topup</a></li>
                            <li [ngClass]="{'active': this.detailTabs['withdrawals']}"><a (click)="setTabPanelActive('withdrawals')"
                                    style="cursor: pointer;">Withdrawals</a></li>
                            <!-- (click)="setTabPanelActive('transactions')" style="cursor: pointer;" -->
                        </ul>
                    </div>
                    <div class="panel" *ngIf="this.detailTabs['personal']">
                        <h3>Personal Information</h3>
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit"  [(ngModel)]="this.detailViewed['email']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Mobile Number</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['mobile']">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Address</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['distributionArea']">
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="panel" *ngIf="this.detailTabs['service_details']">
                        <h3>Service Details</h3>
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Service Area</label>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-md">
                                                <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['distributionArea']">
                                            </div>
                                            <!-- <div class="col-md">
                                                <input type="text" class="form-control" disabled value="Pangasinan">
                                            </div>
                                            <div class="col-md">
                                                <input type="text" class="form-control" disabled value="San Carlos City">
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Plate Number/Conduction Number</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['licensePlate']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Vehicle Type</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['vehicleType']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Service Type</label>
                                    <div class="col-sm-10">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="chk1" value="option1" disabled [checked]="this.detailViewed['partnerVehicles'][0]['isExpress']">
                                            <label class="form-check-label" for="chk1">Express</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="chk3" value="option3" disabled [checked]="this.detailViewed['partnerVehicles'][0]['isMototaxi']">
                                            <label class="form-check-label" for="chk3">MotoTaxi</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="chk2" value="option2" disabled [checked]="this.detailViewed['partnerVehicles'][0]['isOkRide']">
                                            <label class="form-check-label" for="chk2">OK Ride</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel" *ngIf="this.detailTabs['documents']">
                        <div class="list-holder">
                            <div class="tools">
                                <div class="list-title">
                                    <h4>List of Documents</h4>
                                </div>
                                
                                <div class="actions">
                                    <!-- <button *ngIf="checkIfAvailableforApproval() && !this.detailViewed['isApprove']" disabled (click)="this.showConfirmModal(confirmModal, 'verify_partner')" class="btn btn-sm btn-default">APPROVE this Partner</button> -->
                                    <button class="btn btn-sm btn-default" [disabled]="!checkIfAvailableforApproval('document_confirmation') || this.detailViewed['status'] == 'initial_registered'" (click)="showAddDocumentModal(addDocument)"><i class="fa fa-plus"></i> Add Document</button>
                                    &nbsp;
                                    <button class="btn btn-sm btn-default" [disabled]="!checkIfAvailableforApproval('document_confirmation')" (click)="sendFollowUpNotification(this.detailViewed['partnerId'], successModal)"><i class="fa fa-send"></i> Send Document Confirmation</button>
                                    &nbsp;
                                    <button [disabled]="!checkIfAvailableforApproval() || this.detailViewed['status'] == 'approved'" (click)="showConfirmModal(confirmModal, 'verify_partner')" class="btn btn-sm btn-default"><i class="fa fa-check"></i> Approve Partner</button>
                                </div>
                            </div>
                            <div class="list table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Document</th>
                                            <th>Part</th>
                                            <th>Document Reference</th>
                                            <th>Expiration Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="this.detailViewdDocuments.length != 0; else noRecords5">
                                            <tr *ngFor="let docs of this.detailViewdDocuments">
                                                <td>{{ this.verifyDocumentName(docs.type) }}</td>
                                                <td>{{ ((docs.type === docs.subType) ? '-' : this.subDocumentDictionary[docs.subType]) }}</td>
                                                <td
                                                    *ngIf="(docs.type == 'drivers_license' && docs.subType == 'front') || (docs.type == 'vehicle_registration' && docs.subType == 'official_receipt') || (docs.type == 'vehicle_registration' && docs.subType == 'certificate_registration'); else noIDRef">
                                                    {{ docs.idNumber }}</td>
                                                <ng-template #noIDRef>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="(docs.type == 'drivers_license' && docs.subType == 'front'); else noExpDate">{{ docs.expirationDate }}
                                                </td>
                                                <ng-template #noExpDate>
                                                    <td>-</td>
                                                </ng-template>
                                                <td>
                                                    <span class="stat stat-pending" *ngIf="docs.status == 'pending'">Pending</span>
                                                    <span class="stat stat-amend" *ngIf="docs.status == 'ammendment'">For Ammendment</span>
                                                    <span class="stat stat-amend" *ngIf="docs.status == 'resubmission'">For Resubmission</span>
                                                    <span class="stat stat-approved" *ngIf="docs.status == 'approve'">Approved</span>
                                                </td>
                                                <td>
                                                    <button (click)="viewDocument(docModalView, docs)"
                                                        class="btn btn-sm btn-default" style="margin-right: 5px;"><i class="fa fa-eye"></i>&nbsp;View</button>
                                                    <button *ngIf="docs.type == 'drug_test_certificate' || docs.type == 'driving_track_certificate' || docs.type == 'no_modification_certification'" (click)="confirmRemoveDoc(docs, confirmModal)" class="btn btn-sm btn-danger"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                                                </td>
                                                <!--  <td *ngIf="!docs.isDone"><a style="cursor: pointer;" (click)="verifyThisDocument(this.detailViewed['partnerId'], docs)">Ammend Document</a></td> -->
                                            </tr>
                                        </ng-container>
                                        <ng-template #noRecords5>
                                            <tr>
                                                <td colspan="8" class="no-records-msg">
                                                    <h6 class="ponds-contents-med-bold">No records found</h6>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="panel" *ngIf="this.detailTabs['transactions']">
                        <div class="list-holder">
                            <select class="form-select" style="width:30%;margin-right:10px;" [(ngModel)]="this.selectedServiceType"
                                (ngModelChange)="onServiceTypeChange($event)">
                                <option value="express">OK! Express</option>
                                <option value="okride">OK! Ride</option>
                                <option value="mototaxi">OK! MotoTaxi</option>
                            </select>
                            <div class="tools">
                                <!-- <div class="list-title" style="width:15%;">
                                                        <h4 *ngIf="transTabs['express']">List of Express Transactions</h4>
                                                        <h4 *ngIf="transTabs['okride']">List of OK Ride Transactions</h4>
                                                        <h4 *ngIf="transTabs['mototaxi']">List of Motaxi Transactions</h4>
                                                    </div> -->
                                <div class="search mg-right-auto">
                                    <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                    <input (input)="searchDataBooking($event)" [(ngModel)]="this.searchKeywordBooking" type="text" class="text-search"
                                        placeholder="Search">
                                </div>
                                
                                <div class="search mg-right-auto" style="width:58%; ">
                                    <span style="font-weight:bold;margin-right:10px;margin-left:20px;">Sorting: </span>
                                    <select class="form-select" style="width:30%;margin-right:10px;" [(ngModel)]="bookingSortBy"
                                        (ngModelChange)="onBookingSortChange()">
                                        <option value="dateCreated">Date Created</option>
                                        <option value="status">Status</option>
                                        <!-- <option value="totalPrice">Total Price</option> -->
                                    </select>
                            
                                    <div class="btn-group" role="group" aria-label="Sort by.." style="width:5%;">
                                        <button type="button" [ngClass]="{'active': bookingSortDirection === 'asc'}" class="btn btn-secondary"
                                            (click)="bookingSortDirection = 'asc'; onBookingSortChange()">Ascending</button>
                                        <button type="button" [ngClass]="{'active': bookingSortDirection === 'desc'}" class="btn btn-secondary"
                                            (click)="bookingSortDirection = 'desc'; onBookingSortChange()">Descending</button>
                                    </div>
                                </div>
                                <div class="actions">
                                    <button class="btn btn-sm btn-default">Download</button>
                                </div>
                            </div>
                        
                            
                        <div class="list table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Booking ID</th>
                                        <th>Sender</th>
                                        <th>Mobile #</th>
                                        <th>Date of Completion</th>
                                        <th>Vehicle Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="currentBookingSelectedPageListTopups.length != 0; else noRecords4">
                                        <tr *ngFor="let booking of this.currentBookingSelectedPageListTopups">
                                            <td>{{ (selectedServiceType == 'express') ? booking.bookingExpressId : booking.bookingRideId }}</td>
                                            <td>{{ booking.user.name }}</td>
                                            <td>{{ booking.user.mobile }}</td>
                                            <td>{{ booking.dateCompleted | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A'}}</td>
                                            <td>{{ booking.partner.vehicleType }}</td>
                                            <td>
                                                <span class='stat stat-amend' *ngIf="booking.status == 'canceled'">Cancelled</span>
                                                <span class='stat stat-active' *ngIf="booking.status == 'pending'">Pending</span>
                                                <span class='stat stat-amend' *ngIf="booking.status == 'accepted'">Accepted</span>
                                                <span class='stat stat-amend' *ngIf="booking.status == 'ongoing_pickup'">On Going Pickup</span>
                                                <span class='stat stat-approved' *ngIf="booking.status == 'arrive_pickup'">Arrived Pickup</span>
                                                <span class='stat stat-approved' *ngIf="booking.status == 'ongoing_destination'">Ongoing Destination</span>
                                                <span class='stat stat-approved' *ngIf="booking.status == 'drop_off'">Drop Off</span>
                                                <span class='stat stat-approved' *ngIf="booking.status == 'completed'">Completed</span>
                                            </td>
                                            <td><a (click)="viewBookingDetails(booking, bookingDetail, selectedServiceType)" style="cursor: pointer;">View</a></td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #noRecords4>
                                        <tr>
                                            <td colspan="8" class="no-records-msg">
                                                <h6 class="ponds-contents-med-bold">No records found</h6>
                                            </td>
                                        </tr>
                                    </ng-template>
                                   
                                </tbody>
                            </table>
                        </div>
                        <div class="list-footer" *ngIf="this.riderList.length != 0">
                            <div class="pager">
                                <button (click)="nextPrevBooking('prev')" [disabled]="this.disableActionBtnsBooking.prev"><img
                                        src="../../../../assets/okito/images/icons/prev.svg"></button>
                                <button (click)="nextPrevBooking('next')" [disabled]="this.disableActionBtnsBooking.next"><img
                                        src="../../../../assets/okito/images/icons/next.svg"></button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="panel" *ngIf="this.detailTabs['topups']">
                        <div class="list-holder">
                            <div class="tools">
                                <div class="tools-grp">
                                    <div class="search" style="width: 20em !important;">
                                        <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                        <input (input)="searchTopUpData($event)" [(ngModel)]="this.searchKeywordTopup" type="text"
                                            class="text-search" placeholder="Search">
                                    </div>
                                </div>
                                <div class="tools-grp">
                                    <!-- <div class="pager">
                                        <ol>
                                            <li [ngClass]="{'active': dayViewtopUps['days_7']}"><a (click)="fetchWTopUpsByDateRange(7)"
                                                    style="cursor:pointer;">Past 7 Days</a></li>
                                            <li [ngClass]="{'active': dayViewtopUps['days_30']}"><a (click)="fetchWTopUpsByDateRange(30)"
                                                    style="cursor:pointer;">Past 30 Days</a></li>
                                        </ol>
                                    </div> -->
                                    <div class="actions">
                                        <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#create">Download</button>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Partner's Mobile Number</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Top-up Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="currentSelectedPageListTopups.length != 0; else noRecords2">
                                            <tr *ngFor="let topup of this.currentSelectedPageListTopups">
                                                <td>{{ topup.partnerTopupRequestId }}</td>
                                                <td>{{ topup.partner.mobile }}</td>
                                                <td>{{ topup.dateCreated }} </td>
                                                <td>{{ topup.amount | currency:'PHP' }}</td>
                                                <td>{{ topup.source }}</td>
                                                <td><a (click)="viewTopupDetails(topup, topupDetails)" style="cursor: pointer;">View</a></td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #noRecords2>
                                            <tr>
                                                <td colspan="8" class="no-records-msg">
                                                    <h6 class="ponds-contents-med-bold">No records found</h6>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="list-footer">
                                <div class="pager">
                                    <button (click)="nextPrevTopups('prev')" [disabled]="this.disableActionBtnsTopups.prev"><img
                                            src="../../../../assets/okito/images/icons/prev.svg"></button>
                                    <button (click)="nextPrevTopups('next')" [disabled]="this.disableActionBtnsTopups.next"><img
                                            src="../../../../assets/okito/images/icons/next.svg"></button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="panel" *ngIf="this.detailTabs['withdrawals']">
                        <div class="list-holder">
                            <div class="tools">
                                <div class="tools-grp">
                                    <div class="search" style="width: 20em !important;">
                                        <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                        <input (input)="searchWithdrawalData($event)" [(ngModel)]="this.searchKeywordWithdrawal" type="text" class="text-search"
                                            placeholder="Search">
                                    </div>
                                    <!-- <div class="calendar">
                                        <input type="text" name="" class="text-calendar" placeholder="04/18/2024">
                                        <img src="../../../../assets/okito/images/calendar.svg">
                                    </div> -->
                                </div>
                                <div class="tools-grp">
                                    <!-- <div class="pager">
                                        <ol>
                                            <li [ngClass]="{'active': dayViewWithdrawals['days_7']}"><a (click)="fetchWidthdrawalsByDateRange(7)" style="cursor:pointer;">Past 7 Days</a></li>
                                            <li [ngClass]="{'active': dayViewWithdrawals['days_30']}"><a (click)="fetchWidthdrawalsByDateRange(30)" style="cursor:pointer;">Past 30 Days</a></li>
                                        </ol>
                                    </div> -->
                                    <div class="actions">
                                        <button class="btn btn-sm btn-default" data-bs-toggle="modal"
                                            data-bs-target="#create">Download</button>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Partner's Mobile Number</th>
                                            <th>Approved</th>
                                            <th>Requested</th>
                                            <th>Amount</th>
                                            <th>Top-up Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="currentSelectedPageListWithdrawals.length != 0; else noRecords3">
                                            <tr v-else *ngFor="let withdraw of this.currentSelectedPageListWithdrawals">
                                                <td>{{ withdraw.partnerWalletRequestId }}</td>
                                                <td>{{ withdraw.accountName }}</td>
                                                <td>{{ withdraw.dateApproved }} </td>
                                                <td>{{ withdraw.dateCreated }}</td>
                                                <td>{{ withdraw.amount | currency:'PHP' }}</td>
                                                <td>{{ withdraw.accountNumber }}</td>
                                                <td><a (click)="viewWithdrawDetails(withdraw, withdrawDetails)" style="cursor: pointer;">View</a></td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #noRecords3>
                                            <tr >
                                                <td colspan="8" class="no-records-msg">
                                                    <h6 class="ponds-contents-med-bold">No records found</h6>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="list-footer">
                                <div class="pager">
                                    <button class="pager-prev"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                                    <button class="pager-next"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #bookingDetail let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                <small>{{ this.bookingdetailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</small>
                <small>Booking ID</small>
                {{ (selectedServiceType == 'express') ? this.bookingdetailViewed['bookingExpressId'] :
                this.bookingdetailViewed['bookingRideId'] }}
            </h5>
            <span class="stat stat-canceled" *ngIf="this.bookingdetailViewed['status'] === 'pending'">Pending</span>
            <span class="stat stat-ongoing" *ngIf="this.bookingdetailViewed['status'] === 'accepted'">Accepted</span>
            <span class="stat stat-ongoing" *ngIf="this.bookingdetailViewed['status'] === 'ongoing_pickup'">Ongoing -
                Pickup</span>
            <span class="stat stat-ongoing" *ngIf="this.bookingdetailViewed['status'] === 'ongoing_destination'">Ongoing -
                Destination</span>
            <span class="stat stat-completed" *ngIf="this.bookingdetailViewed['status'] === 'completed'">Completed</span>
            <span class="stat stat-canceled" *ngIf="this.bookingdetailViewed['status'] === 'canceled'">Cancelled</span>
            <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <div class="booking-holder">
                <google-map height="250px" width="370px" [center]="center" [zoom]="zoom" (mapClick)="moveMap($event)"
                    (mapMousemove)="move($event)">
                    <map-polyline [path]="polyLinePath" [options]="polyLineOptions" />
                </google-map>
            <!-- <div class="booking-rider-details">
                <div class="pfp">
                    <img src="./../../../assets/okito/images/avatar.png">
                </div>
                <div class="rider-info" *ngIf="this.bookingdetailViewed['partner'] !== null">
                    {{ this.bookingdetailViewed['partner']['firstName'] }} {{ this.bookingdetailViewed['partner']['middleName'] }}
                    {{ this.bookingdetailViewed['partner']['lastName'] }}<br>
                    <small>{{ this.bookingdetailViewed['vehicleCategory'] }} - {{ this.bookingdetailViewed['vehicleId'] }}</small>
                </div>
                <div class="rider-info" *ngIf="this.bookingdetailViewed['partner'] === null">
                    ---
                </div>
                <div class="rider-rating" *ngIf="this.bookingdetailViewed['partner'] !== null">
                    <img src="./../../../assets/okito/images/star.svg">
            
            
                    <h4 *ngIf="selectedServiceType === 'express' && this.bookingdetailViewed['rating'] === null">0.00</h4>
                    <h4 *ngIf="selectedServiceType === 'express' && this.bookingdetailViewed['rating'] !== null">{{
                        this.bookingdetailViewed['rating']['rate'] | number: '1.2-2' }}</h4>
                    <h4 *ngIf="selectedServiceType !== 'express' && this.bookingdetailViewed['partnerRating'] === null">0.00</h4>
                    <h4 *ngIf="selectedServiceType !== 'express' && this.bookingdetailViewed['partnerRating'] !== null">{{
                        this.bookingdetailViewed['partnerRating']['rate'] | number: '1.2-2' }}</h4>
                </div>
            </div> -->
                <div class="booking-address">
                    <div class="booking-address-details">
                        <img src="./../../../assets/okito/images/pickup.svg">
                        <div class="sender-details">
                            <label>Pick-up Location</label>
                            <h4>{{ this.bookingdetailViewed['startFormattedAddress'] }}</h4>
                            <div class="sender">
                                <span>{{ this.bookingdetailViewed['senderName'] }}</span>
                                <span>{{ this.bookingdetailViewed['senderMobile'] }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="booking-address-details">
                        <img src="./../../../assets/okito/images/dropoff.svg">
                        <div class="sender-details">
                            <h4>{{ this.bookingdetailViewed['endFormattedAddress'] }}</h4>
                            <div class="sender">
                                <span>{{ this.bookingdetailViewed['receipientName'] }}</span>
                                <span>{{ this.bookingdetailViewed['receipientMobile'] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="booking-note">
                    <label>Note to rider</label>
                    <p>{{ this.bookingdetailViewed['notes'] }}</p>
                </div>
                <div class="booking-distance">
                    <div class="fees-grp">
                        <div class="distance-label">
                            <img src="./../../../assets/okito/images/dropoff.svg">
                            <label>Distance</label>
                        </div>
                        <span>{{ (selectedServiceType == 'express') ? this.bookingdetailViewed['totalDistance'] :
                            this.bookingdetailViewed['kmDistance'] }} km</span>
                    </div>
                </div>
                <div class="booking-fees">
                    <div class="fees-grp">
                        <label>Delivery Fee</label>
                        <span>{{ this.bookingdetailViewed['farePrice'] | currency:'PHP' }}</span>
                    </div>
                    <div class="fees-grp">
                        <label>Discount</label>
                        <span class="discount">-{{ this.bookingdetailViewed['discountPrice'] | currency:'PHP' }}</span>
                    </div>
                    <!-- <div class="fees-grp">
                        <label>Insurance</label>
                        <span>{{ this.bookingdetailViewed['insurancePrice'] | currency:'PHP' }}</span>
                    </div> -->
                    <div class="total-fees">
                        <label>Total Delivery Fee</label>
                        <div class="total">
                            <img src="./../../../assets/okito/images/cash.svg">
                            <span>{{ this.bookingdetailViewed['totalPrice'] | currency:'PHP' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <ng-template #withdrawDetails let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                <small>{{ this.withdrawdetailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A'
                    }}</small>
            </h5>
            <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <div class="booking-holder">
                    <h5>Withdrawal Details</h5>
                    <div class="details-grp">
                        <label>Transaction ID</label>
                        <span>{{ this.withdrawdetailViewed['partnerWalletRequestId'] }}</span>
                    </div>
                    <div class="details-grp">
                        <label>Partner's Mobile Number</label>
                        <span>{{ this.withdrawdetailViewed['accountName'] }}</span>
                    </div>
                    <div class="details-grp">
                        <label>Partner's Email Address</label>
                        <span>{{ this.withdrawdetailViewed['partner']['email'] }}</span>
                    </div>
                    <div class="details-grp">
                        <label>Partner's Name</label>
                        <span>{{ this.withdrawdetailViewed['partner']['firstName'] + ' ' + this.withdrawdetailViewed['partner']['lastName']}}</span>
                    </div>
                    <div class="details-grp">
                        <label>Vehicle Type</label>
                        <span>{{ this.withdrawdetailViewed['partner']['vehicleType'] }}</span>
                    </div>
                   <!--  <div class="details-grp">
                        <label>Service Type</label>
                        <span>Ok! Express, Ok! MotoTaxi</span>
                    </div> -->
                    <div class="details-grp">
                        <label>Amount</label>
                        <span>{{ this.withdrawdetailViewed['amount'] | currency:'PHP' }}</span>
                    </div>
                    <div class="details-grp">
                        <label>Top-Up Type</label>
                        <span>{{ withdrawdetailViewed['accountNumber'] | uppercase }}</span>
                    </div>
                    <div class="details-grp">
                        <label>Reference</label>
                        <span>{{ this.withdrawdetailViewed['bankName'] +  ' - ' + this.withdrawdetailViewed['accountNumber']}}</span>
                    </div>
                </div>
            
        </div>
    </ng-template>


    <ng-template #topupDetails let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                <small>{{ this.topupdetailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A'
                    }}</small>
            </h5>
            <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <div class="booking-holder">
                <h5>Top Up Details</h5>
                <div class="details-grp">
                    <label>Transaction ID</label>
                    <span>{{ this.topupdetailViewed['partnerTopupRequestId'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Mobile Number</label>
                    <span>{{ this.topupdetailViewed['partner']['mobile'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Email Address</label>
                    <span>{{ this.topupdetailViewed['partner']['email'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Name</label>
                    <span>{{ this.topupdetailViewed['partner']['firstName'] + ' ' +
                        this.topupdetailViewed['partner']['lastName']}}</span>
                </div>
                <div class="details-grp">
                    <label>Vehicle Type</label>
                    <span>{{ this.topupdetailViewed['partner']['vehicleType'] }}</span>
                </div>
                <!-- <div class="details-grp">
                    <label>Service Type</label>
                    <span>Ok! Express, Ok! MotoTaxi</span>
                </div> -->
                <div class="details-grp">
                    <label>Amount</label>
                    <span>{{ this.topupdetailViewed['amount'] | currency:'PHP' }}</span>
                </div>
                <!-- <div class="details-grp">
                    <label>Top-Up Type</label>
                    <span>Partner</span>
                </div> -->
                <div class="details-grp">
                    <label>Source</label>
                    <span>{{ this.topupdetailViewed['source'] }}</span>
                </div>
            </div>
    
        </div>
    </ng-template>
    
</div>


<ng-template #docModalView let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{ this.documentDictionary[this.currentSelectedDocument] }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <!-- ID PHOTO -->
        <div class="modal-form" *ngIf="this.formDocumentType['selfie'] || this.formDocumentType['spa_letter'] || this.formDocumentType['no_modification_certification'] || this.formDocumentType['driving_track_certificate'] || this.formDocumentType['drug_test_certificate'] || this.formDocumentType['id'] || this.formDocumentType['medical_certificate'] || this.formDocumentType['truck_permit']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }}</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                            <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)" style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- DRIVERS LICENSE FRONT -->
        <div class="modal-form" *ngIf="this.formDocumentType['drivers_license']['front']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }} (Front)</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Driver's License ID</label>
                <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.idNumber">
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-item">
                        <label class="form-label">First Name</label>
                        <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.firstName">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-item">
                        <label class="form-label">Middle Name</label>
                        <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.middleName">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-item">
                        <label class="form-label">Last Name</label>
                        <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.lastName">
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Address</label>
                <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.address">
            </div>
            <div class="row publish-date">
                <div class="col-md-12">
                    <div class="form-item">
                        <label class="form-label">Expiration Date</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Gender</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="this.selectedDocInfo.gender">
                    <option value="true">Male</option>
                    <option value="false">Female</option>
                </select>
            </div>
        </div>
        
        <!-- DRIVERS LICENSE BACK -->
        <div class="modal-form" *ngIf="this.formDocumentType['drivers_license']['back']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }} (Back)</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- INSURANCE -->
        <div class="modal-form" *ngIf="this.formDocumentType['insurance']['front'] || this.formDocumentType['insurance']['back']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }}</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>

            <div class="row publish-date">
                <div class="col-md-12">
                    <div class="form-item">
                        <label class="form-label">Expiration Date</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- VEHICLE OR -->
        <div class="modal-form" *ngIf="this.formDocumentType['vehicle_registration']['official_receipt']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }} (Official Receipt)</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">OR Number</label>
                <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.idNumber">
            </div>
            <div class="row publish-date">
                <div class="col-md-12">
                    <div class="form-item">
                        <label class="form-label">Date Issued</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Plate Number/Conduction Number</label>
                <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.address">
            </div>
        </div>
 
        <!-- VEHICLE CR -->
        <div class="modal-form" *ngIf="this.formDocumentType['vehicle_registration']['certificate_registration']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }} (Certificate of Registration)</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">CR Number</label>
                <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.idNumber">
            </div>
            <div class="row publish-date">
                <div class="col-md-12">
                    <div class="form-item">
                        <label class="form-label">Date Issued</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Plate Number/Conduction Number</label>
                <input type="text" class="form-control" [(ngModel)]="this.selectedDocInfo.address">
            </div>
        </div>
        
        <!-- VEHICLE PHOTO -->
        <form class="modal-form" *ngIf="this.formDocumentType['vehicle_photo']['front'] || this.formDocumentType['vehicle_photo']['left'] || this.formDocumentType['vehicle_photo']['back'] || this.formDocumentType['vehicle_photo']['right']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }} </label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
        </form>    
        
        <!-- NBI -->
        <form class="modal-form" *ngIf="this.formDocumentType['nbi_certificate']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }}</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
            <label style="font-size: 14px;font-weight: italic;" class="form-label">Note: You can try to verify the NBI clearance <a href="https://verification.nbi-clearance.io/" target="_blank">here</a></label>
        </form>

        <!-- https://verification.nbi-clearance.io/ -->

        <!-- GCASH SETTLE MENT -->
        <form class="modal-form" *ngIf="this.formDocumentType['gcash_settlement']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }}</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                            style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
        </form>

        <!-- TNVS -->
        <form class="modal-form" *ngIf="this.formDocumentType['tnvs_certificate']">
            <div class="form-item">
                <label class="form-label">{{ this.documentDictionary[this.currentSelectedDocument] }}</label>
                <div class="modal-photo">
                    <a href="../../../../assets/okito/images/docs.png" target="_blank" class="docs">
                        <div class="docs-holder">
                            <img [src]="this.selectedDocInfo['link']" *ngIf="this.selectedDocInfo.link != ''">
                        </div>
                    </a>
                    <div class="btn-photo" *ngIf="this.selectedDocInfo.link != ''">
                        <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)" style="margin-right: .5em;">View Attachment</button>
                        <a [href]="this.selectedDocInfo['link']">
                            <button type="button" class="btn btn-sm btn-outline">Download Photo</button>
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" >
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Close</button>
        <button *ngIf="this.selectedDocInfo['status'] !== 'ammendment'" type="button" class="btn btn-outline" (click)="this.showConfirmModal(confirmModal, 'amend')">For Amendment</button>
        <button *ngIf="this.selectedDocInfo['status'] !== 'approve'" type="button" class="btn btn-default" (click)="this.showConfirmModal(confirmModal, 'verify_doc')">Validate</button>
    </div>
</ng-template>

<ng-template #successModal let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Success</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeAllModals()"></button>
    </div>

    <!-- SUCCESS AMEND -->
    <div class="modal-body success-body" *ngIf="this.confirmSelection['amend']">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Partner verification is marked for amendment</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body" *ngIf="this.confirmSelection['verify_doc']">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Document is validated</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>

    <div class="modal-body success-body" *ngIf="this.confirmSelection['verify_partner']">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Partner validated</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>

    <div class="modal-body success-body" *ngIf="this.confirmSelection['remove_doc']">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Document Removed</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>

    <!-- SUCCESS AMEND -->
    <div class="modal-body success-body" *ngIf="this.confirmSelection['follow_up']">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Follow up Email & SMS notification has been sent</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmModal let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Confirmation</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    
    <!-- FOR AMMEND CONFIRM -->
    <div class="modal-body" *ngIf="this.confirmSelection['amend']">
        <p>Are you sure you want to amend this document?</p>
        <form class="modal-form reason">
            <div class="form-item">
                <label class="form-label">Reason</label>
                <textarea class="form-control" rows="3"></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="this.confirmSelection['amend']">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="validateThisDocument(successModal, 2 , this.detailViewed['partnerId'], this.selectedDocInfo)">Yes, Amend</button>
    </div>

    
    <!-- FOR CONFIRM VALIDATE DOCS -->
    <div class="modal-body" *ngIf="this.confirmSelection['verify_doc']">
        <p>Are you sure you want to validate this document?</p>
    </div>
    <div class="modal-footer" *ngIf="this.confirmSelection['verify_doc']">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="validateThisDocument(successModal, 1,  this.detailViewed['partnerId'], this.selectedDocInfo)">Yes, Validate</button>
    </div>


    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body" *ngIf="this.confirmSelection['verify_partner']">
        <p>Are you sure you want to validate this partner?</p>
    </div>
    <div class="modal-footer" *ngIf="this.confirmSelection['verify_partner']">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="verifyPartner(successModal ,this.detailViewed['partnerId'])">Yes, Validate</button>
    </div>


    <!-- FOR CONFIRM REMOVE DOCUMENT -->
    <div class="modal-body" *ngIf="this.confirmSelection['remove_doc']">
        <p>Are you sure you want to remove this document?</p>
    </div>
    <div class="modal-footer" *ngIf="this.confirmSelection['remove_doc']">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default"
            (click)="removeDocument(documentForRemoval , successModal)">Yes, Remove</button>
    </div>
</ng-template>

<ng-template #viewImageLarger let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Attachment Viewing</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <div class="image-bigviewer">
            <lib-ngx-image-zoom zoomMode="click" 
                [thumbImage]="selectedDocInfo.link" 
                *ngIf="selectedDocInfo.link" 
                [magnification]="1">
            </lib-ngx-image-zoom>
        </div>
    </div>
    <div class="modal-footer">
        <div style="width: 25vh;" *ngIf="!this.detailViewed['isApprove']">
            <button type="button" class="btn btn-outline" (click)="this.showConfirmModal(confirmModal, 'amend')">For
                Amendment</button>
        </div>

        <div style="width: 25vh;" *ngIf="!this.detailViewed['isApprove']">
            <button type="button" class="btn btn-default" (click)="this.showConfirmModal(confirmModal, 'verify_doc')">Validate</button>
        </div>

        <div style="width: 25vh;">
            <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Close</button>
        </div>
    </div>
</ng-template>


<ng-template #topupForm let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Partner Topup (Manual)</h5>
        <!-- <div class="modal-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="chk1" [(ngModel)]="this.newAdPublishToggle">
                <label class="form-check-label" for="chk1">Publish Now</label>
            </div>
        </div> -->
    </div>
    <div class="modal-body">
        <div class="modal-form">

            <div class="form-item">
                <label class="form-label">Amount to Top Up</label>
                <input type="text" class="form-control" placeholder="Enter title" [(ngModel)]="topUpForm['amt']">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-default" (click)="confirmManualTopUp(confirmTopup)">Top up</button>
    </div>
</ng-template>


<ng-template #confirmTopup let-modal id="photo" class="modal">
    <div class="modal-body">
        <p>Are you sure you want to proceed the topup for this partner?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="processTopUp(sucesssTopUp)">Yes, Proceed</button>
    </div>
</ng-template>

<ng-template #sucesssTopUp let-modal id="photo" class="modal">
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Topup success</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>

<ng-template #addDocument let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Document</h5>
    </div>
    <div class="modal-body">
        <div class="modal-form">
            <div class="form-item">
                <label class="form-label">Type</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="documentFormNew['type']">
                    <option value="drug_test_certificate">Drug Test Result</option>
                    <option value="driving_track_certificate">Driving / Track Pass Certification</option>
                    <option value="no_modification_certification">No Modification Certification</option>
                </select>
            </div>
            <div class="form-item">
                <label class="form-label">Document Photo</label>
                <div class="modal-upload-photo">
                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)"
                        accept="image/*">
                    <div class="docs-holder">
                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                    </div>
                    <div class="btn-photo">
                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-default" (click)="createDocumentData(confirmAddDocument)">Add this Document</button>
    </div>
</ng-template>

<ng-template #confirmAddDocument let-modal class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to add this Document?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateDocumentData(successCreate)">Yes, Add</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Document successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>
