<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Pricing Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Pricing Management</h2>
                </div>
                <hr>

                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing1" role="button" aria-expanded="false"
                                aria-controls="pricing1">
                                <h3>Distance-Based Pricing</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal"
                                    data-bs-target="#edit-pricing-distance">Edit Pricing</button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse show" id="pricing1">
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Distance(per km)</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <input type="text" class="form-control" class="form-control" disabled value="2">
                                            <span class="input-group-text" id="basic-addon2">KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Rate</label>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-md">
                                                <input type="text" class="form-control" disabled value="Flat Rate">
                                            </div>
                                            <div class="col-md">
                                                <div class="input-group">
                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                    <input type="text" class="form-control" class="form-control" disabled value="20.00">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing2" role="button" aria-expanded="false"
                                aria-controls="pricing2">
                                <h3>Time-Based Pricing</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="pricing2">
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Date/Time</label>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-md">
                                                <input type="text" class="form-control" disabled value="Apr 22, 2024">
                                            </div>
                                            <div class="col-md">
                                                <input type="text" class="form-control" disabled value="1:00 AM">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Distance(per km)</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <input type="text" class="form-control" class="form-control" disabled value="2">
                                            <span class="input-group-text" id="basic-addon2">KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Rate</label>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-md">
                                                <input type="text" class="form-control" disabled value="Flat Rate">
                                            </div>
                                            <div class="col-md">
                                                <div class="input-group">
                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                    <input type="text" class="form-control" class="form-control" disabled value="20.00">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing3" role="button" aria-expanded="false"
                                aria-controls="pricing3">
                                <h3>Weight or Size-Based Pricing</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing3">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing4" role="button" aria-expanded="false"
                                aria-controls="pricing4">
                                <h3>Dynamic Pricing</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing4">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing5" role="button" aria-expanded="false"
                                aria-controls="pricing5">
                                <h3>Minimum Order Value</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing5">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing6" role="button" aria-expanded="false"
                                aria-controls="pricing6">
                                <h3>Surge Pricing</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing6">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing7" role="button" aria-expanded="false"
                                aria-controls="pricing7">
                                <h3>Promotions and Discounts</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing7">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing8" role="button" aria-expanded="false"
                                aria-controls="pricing8">
                                <h3>Multi-Stop Deliveries</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing8">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing9" role="button" aria-expanded="false"
                                aria-controls="pricing9">
                                <h3>Payment Gateways</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse" id="pricing9">
                                                <div class="panel-info">
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Free</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" class="form-control" disabled value="Free">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Reqular</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-grp">
                                                        <div class="mb-3 row">
                                                            <label class="col-sm-2 col-form-label">Enhance</label>
                                                            <div class="col-sm-10">
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon2">PHP</span>
                                                                    <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                </div>
                
                <div class="panel">
                    <div class="list-holder">
                        <div class="tools">
                            <div class="tools-title" data-bs-toggle="collapse" href="#pricing10" role="button" aria-expanded="false"
                                aria-controls="pricing10">
                                <h3>Insurance</h3>
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#edit-distance">Edit
                                    Pricing</button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="pricing10">
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Free</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled value="Free">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Reqular</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <span class="input-group-text" id="basic-addon2">PHP</span>
                                            <input type="text" class="form-control" class="form-control" disabled value="7.00">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Enhance</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <span class="input-group-text" id="basic-addon2">PHP</span>
                                            <input type="text" class="form-control" class="form-control" disabled value="9.00">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>