<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Rewards Management</span></li>
                    </ol>
                </div>
                <div class="content-title title-row">
                    <h2>Rewards Management</h2>
                    <!-- <div class="conversion">
                        <img src="../../../../assets/okito/images/conversion.svg">
                        <span>1 KM = 1 Point</span>
                        <button class="btn btn-sm btn-outline" data-bs-toggle="modal"
                            data-bs-target="#edit-conversion">Edit</button>
                    </div> -->
                </div>
                <hr>
                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Okito Voucher</small>
                                    <h4>0</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/points.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Digital Voucher</small>
                                    <h4>0</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/digital.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Raffle Item</small>
                                    <h4>{{ rewardsList.length }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/raffle.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-tabs">
                    <ul>
                        <li class="active"><a >Raffle Item</a></li>
                        <li><a>Okito Voucher</a></li>
                        <li><a>Digital Voucher</a></li>
                    </ul>
                </div>
                <div class="list-holder">
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" name="" class="text-search" placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" (click)="showCreateRewardForm(createVehicle)">Create
                                Reward</button>
                        </div>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Points</th>
                                    <th>Start Date</th>
                                    <th>Audience</th>
                                    <th>Number of Winners</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let rewards of this.currentSelectedPageList">
                                        <td>{{ rewards.rewardRaffleId }}</td>
                                        <td>{{ rewards.title }}</td>
                                        <td>{{ rewards.points }}</td>
                                        <td>{{ rewards.dateCreated }}</td>
                                        <td>{{ rewards.audience | uppercase }}</td>
                                        <td>{{ rewards.noWinner }}</td>
                                        <td>
                                            <span class="stat stat-published" *ngIf="rewards.status == 'ongoing'">{{ rewards.status | uppercase }}</span>
                                            <span class="stat stat-unpublished" *ngIf="rewards.status == 'ended'">{{ rewards.status | uppercase }}</span>
                                        </td>
                                        <td><a (click)="viewDetails(rewards)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button class="pager-prev"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button class="pager-next"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #showDetailComponent>
        <div class="main-holder">
               <app-nav></app-nav>
                <div class="main">
                    <div class="main-content">
                        <div class="bread">
                            <ol>
                                <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                                <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                <li>Rewards Management</li>
                                <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                <li><span>Reward Details</span></li>
                            </ol>
                        </div>
                        
                        <div class="profile-holder">
                            <div class="profile">
                                <!-- <div class="ads-img">
                                    <img src="../../../../assets/okito/images/docs.png">
                                </div> -->
                                <div class="profile-info">
                                    <h4>{{ this.detailViewed['title'] }}</h4>
                                    <div class="tags">
                                        <span class="stat stat-published">Type: RAFFLE</span>
                                        <span class="stat stat-published">For: {{ this.detailViewed['audience'] | uppercase }}</span>
                                        <span class="stat stat-published" *ngIf=" this.detailViewed['status'] == 'ongoing'">Status: {{ this.detailViewed['status'] | uppercase }}</span>
                                        <span class="stat stat-unpublished" *ngIf=" this.detailViewed['status'] == 'ended'">Status: {{ this.detailViewed['status'] | uppercase }}</span>
                                        <span class="stat stat-published">Joined Users: {{ this.detailViewed['rewardRaffleEntryList'].length }}</span>
                                    </div>
                                
                                </div>
                            </div>
                            <div class="profile-actions" *ngIf="this.enableEdit; else disableEdit">
                                <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="disableEditForm()">Cancel Edit</button>
                                <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="updateReward(confirmUpdate)">Save Details</button>
                            </div>
                            
                            <ng-template #disableEdit>
                                <div class="profile-actions">
                                    <button class="btn btn-sm btn-default" (click)="enableEditForm();">Edit Reward</button>
                                    <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                                </div>
                            </ng-template>
                        </div>
                        <!-- <div class="cards">
                            <div class="row">
                                <div class="col-md">
                                    <div class="card-item">
                                        <div class="card-content">
                                            <small>Redeemed</small>
                                            <h4>1000</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/raffle.svg">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="card-item">
                                        <div class="card-content">
                                            <small>Joined Customer</small>
                                            <h4>1000</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/users.svg">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="card-item">
                                        <div class="card-content">
                                            <small>Joined Partner</small>
                                            <h4>0</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="panel">
                            <h3>Rewards Information</h3>
                            <div class="panel-info">
                                <div class="panel-grp" *ngIf="!enableEdit">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Image</label>
                                        <div class="col-sm-10">
                                            <img src="{{ this.detailViewed['photo'] }}" class="reward">
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-upload-photo" *ngIf="enableEdit">
                                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                                    <div class="docs-holder">
                                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                                    </div>
                                    <div class="btn-photo">
                                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload New Photo</label>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Type</label>
                                        <div class="col-sm-10">
                                            <input disabled type="text" class="form-control" disabled value="Raffle Item">
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Audience</label>
                                        <div class="col-sm-10">
                                            <!-- <input type="text" class="form-control" [disabled]="!enableEdit" value="{{ detailViewed['audience'] | uppercase}}"> -->
                                            <select disabled [(ngModel)]="this.detailViewed['audience']" class="form-select" aria-label="Default select example">
                                                <option value="consumer">Consumer</option>
                                                <option value="partner">Partner</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Title</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['title']">
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Number of Winners</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['noWinner']">
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Points</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['points']">
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Mechanics</label>
                                        <div class="col-sm-10">
                                            <textarea class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['mechanics']"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-form-label">Terms and Conditions</label>
                                        <div class="col-sm-10">
                                            <textarea class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['termsCondition']"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-md-2 col-form-label">Start Date and Time</label>
                                        <div *ngIf="!enableEdit" class="col-sm-10  col-md-10">
                                            <input type="text" class="form-control" disabled value="{{ this.detailViewed['dateStart'] }}">
                                        </div>
                                        <div *ngIf="enableEdit" class="col-md-7 col-sm-7">
                                            <div class="form-item">
                                                <div class="form-control-grp">
                                                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                                        #d="ngbDatepicker" />
                                                    <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="enableEdit" class="col-md-3 col-sm-3">
                                            <div class="form-item">
                                                <label class="form-label"></label>
                                                <div class="form-control-grp"
                                                    style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                                                    <ngb-timepicker [(ngModel)]="time" [meridian]="meridian" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-md-2 col-form-label">End Date and Time:</label>
                                        <div *ngIf="!enableEdit" class="col-sm-10 col-md-10">
                                            <input type="text" class="form-control" disabled value="{{ detailViewed['dateEnd'] }}">
                                        </div>
                                        <div *ngIf="enableEdit" class="col-md-7 col-sm-7">
                                            <div class="form-item">
                                                <div class="form-control-grp">
                                                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model2" ngbDatepicker
                                                        #dd="ngbDatepicker" />
                                                    <img src="../../../../assets/okito/images/calendar.svg" (click)="dd.toggle()" style="cursor: pointer;">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="enableEdit" class="col-md-3 col-sm-3">
                                            <div class="form-item">
                                                <label class="form-label"></label>
                                                <div class="form-control-grp"
                                                    style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                                                    <ngb-timepicker [(ngModel)]="time2" [meridian]="meridian" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-3 row">
                                        <label class="col-sm-2 col-md-2 col-form-label">Draw Date and Time</label>
                                        <div *ngIf="!enableEdit" class="col-sm-10 col-md-10">
                                            <input type="text" class="form-control" disabled value="{{ detailViewed['dateDraw'] }}">
                                        </div>
                                        <div *ngIf="enableEdit" class="col-md-7 col-sm-7">
                                            <div class="form-item">
                                                <div class="form-control-grp">
                                                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model3" ngbDatepicker
                                                        #ddd="ngbDatepicker" />
                                                    <img src="../../../../assets/okito/images/calendar.svg" (click)="ddd.toggle()" style="cursor: pointer;">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="enableEdit" class="col-md-3 col-sm-3">
                                            <div class="form-item">
                                                <label class="form-label"></label>
                                                <div class="form-control-grp"
                                                    style="align-items: center; justify-content: center; display: flex; height: 60px;">
                                                    <ngb-timepicker [(ngModel)]="time3" [meridian]="meridian" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </ng-template>
</div>


<ng-template #createVehicle let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Rewards</h5>
        <!-- <div class="modal-check">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="chk1" value="option1" checked>
                                    <label class="form-check-label" for="chk1">Publish Now</label>
                                </div>
                            </div> -->
    </div>
    <div class="modal-body">

        <div class="form-item">
            <label class="form-label">Icon</label>
            <div class="modal-upload-photo">
                <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)"
                    accept="image/*">
                <div class="docs-holder">
                    <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                </div>
                <div class="btn-photo">
                    <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                </div>
            </div>
        </div>

        <div class="form-item">
            <label class="form-label">Type</label>
            <select [(ngModel)]="this.newRewardsForm['type']" class="form-select" aria-label="Default select example">
                <option value="okito_voucher">Okito Voucher</option>
                <option value="digital_voucher">Digital Voucher</option>
                <option value="raffle_item" selected>Raffle Item</option>
            </select>
        </div>
        <div class="form-item">
            <label class="form-label">Audience</label>
            
            <select [(ngModel)]="this.newRewardsForm['audience']" class="form-select" aria-label="Default select example">
                <!-- <option value="all">All</option> -->
                <option value="CONSUMER">Consumer</option>
                <option value="PARTNER">Partner</option>
            </select>
        </div>
        <div class="form-item form-error">
            <label class="form-label">Title</label>
            <input [(ngModel)]="this.newRewardsForm['title']" type="text" class="form-control" placeholder="Enter title">
        </div>
        <div class="form-item">
            <label class="form-label">Number of Winners</label>
            <input [(ngModel)]="this.newRewardsForm['noWinner']" type="text" class="form-control" placeholder="Enter number of winners">
        </div>
        <div class="form-item">
            <label class="form-label">Points</label>
            <input [(ngModel)]="this.newRewardsForm['points']" type="text" class="form-control" placeholder="Enter title">
        </div>
        <div class="form-item">
            <label class="form-label">Mechanics</label>
            <textarea [(ngModel)]="this.newRewardsForm['mechanics']" class="form-control" rows="3" placeholder="Enter description"></textarea>
        </div>
        <div class="form-item">
            <label class="form-label">Terms and Conditions</label>
            <textarea [(ngModel)]="this.newRewardsForm['termsCondition']" class="form-control" rows="3" placeholder="Enter description"></textarea>
        </div>
        <br>
        <div class="row publish-date">
            <div class="col-md-9">
                <div class="form-item">
                    <label class="form-label">Start Date and Time</label>
                    <div class="form-control-grp">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                            #d="ngbDatepicker" />
                        <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-item">
                    <label class="form-label"></label>
                    <div class="form-control-grp" style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                        <ngb-timepicker [(ngModel)]="time" [meridian]="meridian" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row publish-date">
            <div class="col-md-9">
                <div class="form-item">
                    <label class="form-label">End Date and Time:</label>
                    <div class="form-control-grp">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model2" ngbDatepicker
                            #dd="ngbDatepicker" />
                        <img src="../../../../assets/okito/images/calendar.svg" (click)="dd.toggle()" style="cursor: pointer;">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-item">
                    <label class="form-label"></label>
                    <div class="form-control-grp"
                        style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                        <ngb-timepicker [(ngModel)]="time2" [meridian]="meridian" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row publish-date">
            <div class="col-md-9">
                <div class="form-item">
                    <label class="form-label">Draw Date and Time</label>
                    <div class="form-control-grp">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model3" ngbDatepicker
                            #ddd="ngbDatepicker" />
                        <img src="../../../../assets/okito/images/calendar.svg" (click)="ddd.toggle()" style="cursor: pointer;">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-item">
                    <label class="form-label"></label>
                    <div class="form-control-grp"
                        style="align-items: center; justify-content: center; display: flex; height: 60px;">
                        <ngb-timepicker [(ngModel)]="time3" [meridian]="meridian" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" id="publish" class="btn btn-default" (click)="createReward(confirmCreate)"
            data-bs-target="#confirm">Create Reward</button>
    </div>
</ng-template>

<ng-template #confirmCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to create this Reward?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateReward(successCreate)">Yes, Create</button>
    </div>
</ng-template>


<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Rewards successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmUpdate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to update this Reward?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="updateThisDetails(successCreate)">Yes, Update</button>
    </div>
</ng-template>


<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Rewards successfully updated</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>