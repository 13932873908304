<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Vehicle Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Vehicle Management</h2>
                </div>
                <hr>
                <div class="list-holder">
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" (click)="showCreateAdForm(createVehicle)">Add
                                Vehicle</button>

                                
                        </div>
                    </div>

                    <!-- <div class="list-tabs">
                        <ul>
                            <li [ngClass]="{'active': this.vehicleTabs['express']}"><a (click)="setTabPanelActive('express')"
                                    style="cursor: pointer;">Express</a></li>
                            <li [ngClass]="{'active': this.vehicleTabs['mototaxi']}"><a (click)="setTabPanelActive('mototaxi')"
                                    style="cursor: pointer;">MotoTaxi</a></li>
                            <li [ngClass]="{'active': this.vehicleTabs['ride']}"><a (click)="setTabPanelActive('ride')"
                                    style="cursor: pointer;">Ride</a></li>
                        </ul>
                    </div> -->


                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Icon / Image</th>
                                    <th>Vehicle</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let v of this.currentSelectedPageList">
                                        <td>{{ v.vehicleId }}</td>
                                        <td><img src="../../../../assets/okito/images/vehicle/motorcycle.svg"></td>
                                        <td class="vehicle-type">
                                            <h4>{{ v.category }}</h4>
                                            <p>{{ v.description }}</p>
                                            <small>Base Price: {{ v.basePrice }}</small>
                                            <small>Price per KM: {{ v.priceKm }}</small>
                                            <small>Dimensions: {{ v.dimension }}</small>
                                            <small>Weight: {{ v.weight }}</small>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-outline" (click)="editVehicle('Feature Still in Progress')">Edit</button>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #createVehicle let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Vehicle</h5>
        <!-- <div class="modal-check">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="chk1" value="option1" checked>
                                    <label class="form-check-label" for="chk1">Publish Now</label>
                                </div>
                            </div> -->
    </div>
    <div class="modal-body">

            <div class="form-item">
                <label class="form-label">Icon</label>
                <div class="modal-upload-photo">
                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                    <div class="docs-holder">
                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                    </div>
                    <div class="btn-photo">
                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                    </div>
                </div>
            </div>
            
            <div class="form-item form-error">
                
                <label class="form-label">Vehicle Category</label>
                <input type="text" class="form-control" placeholder="Enter vehicle" [(ngModel)]="vehicleNew['category']">
            </div>
            <div class="form-item">
                <label class="form-label">Description</label>
                <textarea class="form-control" rows="2" placeholder="Enter description" [(ngModel)]="vehicleNew['description']"></textarea>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Base Price</label>
                        <input type="text" class="form-control" placeholder="e.g. 0.5 x 0.4 x 0.5 meter" [(ngModel)]="vehicleNew['basePrice']">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Price per KM</label>
                        <input type="text" class="form-control" placeholder="e.g. Up to 20 kg" [(ngModel)]="vehicleNew['priceKm']">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Dimension</label>
                        <input type="text" class="form-control" placeholder="e.g. 0.5 x 0.4 x 0.5 meter"
                            [(ngModel)]="vehicleNew['dimension']">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Weight </label>
                        <input type="text" class="form-control" placeholder="e.g. Up to 20 kg" [(ngModel)]="vehicleNew['weight']">
                    </div>
                </div>
            </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" id="publish" class="btn btn-default" (click)="createNewVehicle(confirmCreate)"
            data-bs-target="#confirm">Add</button>
    </div>
</ng-template>

<ng-template #confirmCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to create this Vehicle Category?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateVehicle(successCreate)">Yes, Create</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Vehicle Category successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>