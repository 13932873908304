
import { Component, inject, TemplateRef, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '../footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DashboardService } from '@services/dashboard.service';
import { LocalService } from '@services/local.service';
import { Ad } from '@interfaces/models.interface'
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { MomentModule } from 'ngx-moment';

@Component({
    selector: 'app-dashboard-ads-manage',
    standalone: true,
    templateUrl: './dashboard-ads-manage.component.html',
    styleUrl: './dashboard-ads-manage.component.css',
    imports: [NgbDatepickerModule, NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, JsonPipe, NgbTimepickerModule, RouterModule, MomentModule],
})
export class DashboardAdsManageComponent {
  today = new Date();
  imageSrc?: string | ArrayBuffer | null; 
  model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
  time = { hour: 0, minute: 0 };
	meridian = false;
  
  constructor(
      private dasher: DashboardService,
      private localservice: LocalService,
  ) { }

  private modalService = inject(NgbModal);
  public adsList: Ad[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: Ad[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public adPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public detailViewed: Record<string, any> = {};
  public showDetails: boolean = false;
  public adFormNew: Record<string, any> = {
    title: '',
    subTitle: '',
    description: '',
    photo: '',
    link: '',
    status: '',
    publishedDate: ''
  }
  public newAdPublishToggle: boolean = false;
  adPhoto: File | null = null;

  public enableEdit: boolean = false;

  ngOnInit(): void {
    this.fetchAds('all');
  }

  fetchAds(status:string, action: string = ''){
      var fromPage = 1
      if(action != ''){
          fromPage = this.currentPage;
          switch(action){
              case 'next':
                  this.currentPage++
              break;
    
              case 'prev':
                  if(this.currentPage != 1){
                      this.currentPage--
                  }//end if
              break;
          }//end swithc    
      }//end if
      
      this.dasher.tryFetchAds(status, this.currentPage).subscribe((res: any) => {
        if(res.length != 0){
            this.adsList = [];
            res.forEach((ad_instance : any) => {
                let i = ad_instance;
                this.adsList.push(i);
            });

            this.currentSelectedPageList = this.adsList;
        }else{
            this.currentPage = fromPage;
        }//end if
      });
  }//end fn

  nextPrev(action: string){
    switch(action){
        case 'next':
            this.fetchAds('all' , 'next');
        break;

        case 'prev':
            this.fetchAds('all' , 'prev');
        break;
    }//end swithc
}//end fn


searchData(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  this.searchKeyword = inputElement.value;

  if (this.searchKeyword.trim() === '') {
      console.log('Input is empty');
      this.currentSelectedPageList = this.adsList
  }else{
      this.currentSelectedPageList =  this.adsList.filter(object =>
          Object.values(object).some(value => 
              (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
          )
      );
  }//end if
}//end nfn

viewDetails(details: Ad){
    this.showDetails = true;
    this.detailViewed = details;
    console.log(details);
}//end fn

returnToList(){
    this.showDetails = false;
    this.detailViewed = {};

    this.currentPage = 1;
    this.currentSelectedPageList = this.adsList;
}//end fn
  
showCreateAdForm(modal: TemplateRef<any>){
  this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
}//end fn

createNewAd(status: string, confirmModal: TemplateRef <any>){
  let pubdate = this.model?.year + '-' + ((this.model!.month.toString().length > 1) ? this.model!.month: '0'+this.model!.month) + '-' + ((this.model!.day.toString().length > 1) ? this.model!.day: '0'+this.model!.day);
  let pubtime = ((this.time.hour.toString().length > 1) ? this.time.hour: '0'+this.time.hour) + ':' + ((this.time.minute.toString().length > 1) ? this.time.minute: '0'+this.time.minute)+ ':00';
  this.adFormNew['publishedDate'] = pubdate + ' ' + pubtime;
  
  if(status == 'draft'){
    this.adFormNew['status'] = status;
  }else{
    if(this.newAdPublishToggle){
      this.adFormNew['status'] = 'unpublished';
    }else{
      this.adFormNew['status'] = 'published';
    }//end fn
  }//end if

  var errors: number = 0;
  Object.keys(this.adFormNew).forEach((x) => {
    if(x != 'photo'){
      if(this.adFormNew[x] == ''){
        errors ++;
      }//end if
    }//end if
  });

  if(errors != 0){
    alert('Please enter all required fields');
  }else{
    this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
  }//end if
}//end fn

onFileSelected(event: any): void {
  const file: File = event.target.files[0];
  if (file) {
    const reader = new FileReader(); 
    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
    };
    reader.readAsDataURL(file);
    this.adPhoto = file;
  }//end
}//end fn

closeAllModals(){
  this.modalService.dismissAll();
}//end fn

proceedCreateAds(successModal: TemplateRef<any>){
  const formData:any = new FormData();      
  if (this.adPhoto) {
    formData.append('file', this.adPhoto, this.adPhoto.name);
  }

  this.dasher.uploadPhoto(formData, 'ads_photo').subscribe((res: any) => {
    this.adFormNew['photo'] = res.photo;
    this.dasher.createAds(this.adFormNew).subscribe((res: any) => {
      if(res.length != 0){
        this.currentPage = 1;
        this.adsList = [res, ...this.adsList];
        this.maxPages = this.localservice.getTotalPages(this.adsList, this.pageSize);
        this.adPages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
        this.currentSelectedPageList = this.localservice.paginateData(this.adsList, this.currentPage, this.pageSize)
        
        if(this.maxPages == 1){
            this.disableActionBtns.next = true;
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.next = false;
            this.disableActionBtns.prev = true;
        }//end if

        this.modalService.dismissAll();
        Object.keys(this.adFormNew).forEach((x) => {
          this.adFormNew[x] = '';
        });
        this.imageSrc = null;
        this.adPhoto = null;
        this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
      }//end if
    });
  });
}//end fn

enableEditForm(){
  this.enableEdit = true;
}//end fn

disableEditForm(){
  this.enableEdit = false;
}//end fn

updateThisDetails(){
  const formData:any = new FormData();      
  if (this.adPhoto) {
      formData.append('file', this.adPhoto, this.adPhoto.name);
      this.dasher.uploadPhoto(formData, 'ads_photo').subscribe((res: any) => {
          this.detailViewed['photo'] = res.photo;
          this.dasher.updateAds(this.detailViewed).subscribe((res) => {
            alert(res.message);
            this.disableEditForm();
            this.adPhoto = null;
          });
      });
  }else{
    this.dasher.updateAds(this.detailViewed).subscribe((res) => {
      alert(res.message);
      this.disableEditForm();
    });
  }//end if
}//end fn
}
