import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes, Router} from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-verify-admin-acc',
  standalone: true,
  imports:      [ CommonModule ],
  templateUrl: './verify-admin-acc.component.html',
  styleUrl: './verify-admin-acc.component.css'
})
export class VerifyAdminAccComponent {
  constructor(private route: ActivatedRoute, private router: Router) { }
  
  public verify_Status: any = '';

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.verify_Status = params.get('status');

      //if(this.verify_Status != 'success'  this.verify_Status != 'failed'){
        //this.router.navigate(['/notfound']);
      //}
    });
  }

  gotoLogin(){
    this.router.navigate(['/admin/login']);
  }
}
