import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { loginForm, forgotPasswordForm } from './../interfaces/auth.interfaces';
import { CookieService } from 'ngx-cookie-service';
import { Router} from '@angular/router';
import { Rider } from '@interfaces/models.interface';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  constructor(
    private http: HttpClient,
    private cooker: CookieService,
    private router: Router
  ) { }

  tryFetchHomepageSummary(): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}dashboard/overview`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchEarningsSummary(): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}partner/earnings/overview`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchPartnerSummary(): Observable<any> {
    var url = '';

    url = `${environment.apiUrl_okito_official}partner/overview`; // fetches all

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchUserSummary(): Observable<any> {
    var url = '';

    url = `${environment.apiUrl_okito_official}user/overview`; // fetches all

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchCouponSummary(): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}coupon/overview`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchNotification(page:number = 1): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}notification?page=${page}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchConsumers(page:number = 1): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}user?page=${page}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchRiders(status: string = 'all', page:number = 1): Observable<any> {
    var url = '';

    url = `${environment.apiUrl_okito_official}partner?status=${status}&page=${page}`; // fetches all

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchAvailVehicles(page:number = 1): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}vehicle?page=${page}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchPartnerEarnings(status: string = 'all', page:number = 1): Observable<any> {
    var url = '';

    url = `${environment.apiUrl_okito_official}partner/earnings?status=${status}&page=${page}`; // fetches all

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchCustomerBookings(customerID: string, page: number): Observable<any> {
    var url = '';

    url = `${environment.apiUrl_okito_official}user/${customerID}/booking/express?page=${page}`; // fetches all

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchPartnerBookings(partnerID: string, page: number): Observable<any> {
    var url = '';

    url = `${environment.apiUrl_okito_official}booking-express/${partnerID}/txns/express?page=${page}`; // fetches all

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchCoupons(status: string, page:number = 1): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}coupon?type=${status}&page=${page}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchAds(status: string, page:number = 1): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}ads?status=${status}&page=${page}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchNewsAndEvents(page:number = 1): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}news?audience=all&status=all&page=${page}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryValidateDocument(rider_id: string, params: any): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}partner/documents/${rider_id}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.patch<any>(`${url}`, params,  { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryVerifyPartner(rider_id: string): Observable<any> {
    var url = '';
    url = `${environment.apiUrl_okito_official}partner/${rider_id}/approve`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.patch<any>(`${url}`, {},  { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryFetchAdmins(limit: number = 0): Observable<any> {
    var url = '';

    if(limit == 0){
        url = `${environment.apiUrl}admin/dashboard/users`; // fetches all
    }else{
        url = `${environment.apiUrl}admin/dashboard/users/fetch/${limit}`; //fetch with limit
    }//end if

    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn
  
  tryFetchLocations(){
    var url = '';
    url = `${environment.apiUrl_okito_official}location`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.get<any>(`${url}`,  { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  createAds(params: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}ads`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.post<any>(`${url}`, params, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn


  createVehicle(params: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}vehicle`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.post<any>(`${url}`, params, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  createCoupon(params: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}coupon`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.post<any>(`${url}`, params, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  createNewsOrEvent(params:any){
    var url = '';
    url = `${environment.apiUrl_okito_official}news`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.post<any>(`${url}`, params, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  createNotification(params:any){
    var url = '';
    url = `${environment.apiUrl_okito_official}notification`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.post<any>(`${url}`, params, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadPhoto(params: any, destination: string){
    var url = '';
    url = `${environment.apiUrl_okito_official}document/upload?directory=${destination}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      //.set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.post<any>(`${url}`, params, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  updatePartner(partner_id:string, partner_instance: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}partner/${partner_id}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      //.set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.patch<any>(`${url}`, partner_instance, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  updateAds(ads_instance: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}ads`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      //.set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.put<any>(`${url}`, ads_instance, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  updateNews(news_instance: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}news`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      //.set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.put<any>(`${url}`, news_instance, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  updateCoupons(coupon_id: string,coupon_instance: any){
    var url = '';
    url = `${environment.apiUrl_okito_official}coupon/${coupon_id}`; // fetches all
    let token = this.cooker.get(environment.tokenName);
    const headers = new HttpHeaders()
      //.set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
      
    return this.http.put<any>(`${url}`, coupon_instance, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  private handleError(error: any): Observable<any> {
    console.error('An error occurred:', error);
    throw throwError(error);  
  }
}
