<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <!-- <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                                <li><a href="#">User</a></li>
                                                <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                                <li><span>User Details</span></li> -->
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Dashboard</h2>
                </div>
                <div class="list-tabs">
                    <ul>
                        <li [ngClass]="{'active': this.homeTabs['overview']}"><a (click)="setTabPanelActive('overview')" style="cursor: pointer;">Overview</a></li>
                        <li [ngClass]="{'active': this.homeTabs['express']}"><a (click)="setTabPanelActive('express')" style="cursor: pointer;">Express</a></li>
                        <li [ngClass]="{'active': this.homeTabs['mototaxi']}"><a (click)="setTabPanelActive('mototaxi')" style="cursor: pointer;">MotoTaxi</a></li>
                        <li [ngClass]="{'active': this.homeTabs['ride']}"><a (click)="setTabPanelActive('ride')" style="cursor: pointer;">Ride</a></li>
                    </ul>
                </div>
                <div *ngIf="homeTabs['overview']">
                    <div class="cards">
                        <div class="row">
                            <div class="col-md" >
                                <div class="card-item card-gradient">
                                    <div class="card-content">
                                        <small>Total Registered Customer</small>
                                        <h4>{{ this.homepageSummary['totalUser'] | number: '1.0-0' }}</h4>
                                    </div>
                                    <i class="fa fa-users"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards">
                        <div class="row" style="margin-top: 1%;">
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Approved 2-Wheel Partners</small>
                                        <h4>{{ this.homepageSummary['totalApprovedTwowheels'] | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                </div>
                                <div class="row card-child" style="margin-top: 2%;">
                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>2-wheel Express Partners</small>
                                                <h4>{{ this.homepageSummary['rideHailingTwoWheelsCount'] | number: '1.0-0' }}</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/express-icon.png">
                                        </div>
                                    </div>

                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>MotoTaxi Partners</small>
                                                <h4>{{ this.homepageSummary['rideHailingTwoWheelsCount'] | number: '1.0-0' }}</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/mototaxi-icon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Approved 4-Wheel Partners</small>
                                        <h4>{{ this.homepageSummary['totalApprovedFourWheels'] | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                </div>
                                <div class="row card-child"  style="margin-top: 2%;">
                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>4-wheel Express Partners</small>
                                                <h4>{{ this.homepageSummary['expressFourWheelsCount'] | number: '1.0-0' }}</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/express-icon.png">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>4-Wheel Ride Partners</small>
                                                <h4>{{ this.homepageSummary['rideHailingFourWheelsCount'] | number: '1.0-0' }}</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/ride-icon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards">
                        <div class="row"  style="margin-top: 1%;">
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Partner Earnings</small>
                                        <h4>{{ this.homepageSummary['totalPartnerEarnings'] | currency:'PHP' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/payout.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Okito Commission</small>
                                        <h4>{{ this.homepageSummary['totalComissions'] | currency:'PHP' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="homeTabs['express']">
                    <div class="cards">
                        <div class="row">
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Approved Express Partners</small>
                                            <h4>{{ this.expressOverview['twoWheelsPartnerCount'] + this.expressOverview['fourWheelsPartnerCount'] | number: '1.0-0'
                                                }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/express-icon.png">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>2-wheels Partners</small>
                                                    <h4>{{ this.expressOverview['twoWheelsPartnerCount'] | number: '1.0-0' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>4-wheels Partners</small>
                                                    <h4>{{ this.expressOverview['fourWheelsPartnerCount'] | number: '1.0-0' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Bookings</small>
                                            <h4>{{ this.expressOverview['twoWheelsBookingCount'] + this.expressOverview['fourWheelsBookingCount'] | number: '1.0-0' }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/transactions.svg">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>2-wheels Bookings</small>
                                                    <h4>{{ this.expressOverview['twoWheelsBookingCount'] | number: '1.0-0' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>4-wheels Bookings</small>
                                                    <h4>{{ this.expressOverview['fourWheelsBookingCount'] | number: '1.0-0' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Partner Earnings</small>
                                            <h4>{{ this.expressOverview['totalTwoWheelsEarnings'] + this.expressOverview['totalFourWheelsEarnings'] | currency:'PHP' }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/payout.svg">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>2-wheels Earnings</small>
                                                    <h4>{{ this.expressOverview['totalTwoWheelsEarnings'] | currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>4-wheels Earnings</small>
                                                    <h4>{{ this.expressOverview['totalFourWheelsEarnings'] | currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Okito Commissions</small>
                                            <h4>{{ this.expressOverview['totalTwoWheelsComissions'] + this.expressOverview['totalFourWheelsComissions'] | currency:'PHP'
                                                }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>Commissions from 2-wheels</small>
                                                    <h4>{{ this.expressOverview['totalTwoWheelsComissions'] | currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>Commissions from 4-wheels</small>
                                                    <h4>{{ this.expressOverview['totalFourWheelsComissions'] | currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="homeTabs['mototaxi']">
                    <div class="cards">
                        <div class="row">
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Approved MotoTaxi Partners</small>
                                        <h4>{{ this.rideOverview['twoSeatersPartnerCount'] | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/mototaxi-icon.png">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Bookings</small>
                                        <h4>{{ this.rideOverview['twoSeatersBookingCount'] | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/transactions.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Partner Earnings</small>
                                        <h4>{{ this.rideOverview['totalTwoSeatersEarnings'] | currency:'PHP' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/payout.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item card-gray">
                                    <div class="card-content">
                                        <small>Total Okito Commissions</small>
                                        <h4>{{ this.rideOverview['totalTwoSeaterssComissions'] | currency:'PHP' }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="homeTabs['ride']">
                    <div class="cards">
                        <div class="row">
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Approved Express Partners</small>
                                            <h4>{{ this.rideOverview['fourSeatersPartnerCount'] + this.rideOverview['sixSeatersPartnerCount'] | number: '1.0-0' }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/ride-icon.png">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>4-Seater</small>
                                                    <h4>{{ this.rideOverview['fourSeatersPartnerCount'] | number: '1.0-0' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>6-Seater</small>
                                                    <h4>{{ this.rideOverview['sixSeatersPartnerCount'] | number: '1.0-0' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/suv.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Bookings</small>
                                            <h4>{{ this.rideOverview['fourSeatersBookingCount'] + this.rideOverview['sixSeatersBookingCount'] | number: '1.0-0' }}
                                            </h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/transactions.svg">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>4-Seater</small>
                                                    <h4>{{ this.rideOverview['fourSeatersBookingCount'] | number: '1.0-0' }}
                                                    </h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>6-Seater</small>
                                                    <h4>{{ this.rideOverview['sixSeatersBookingCount'] | number: '1.0-0' }}
                                                    </h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/suv.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Partner Earnings</small>
                                            <h4>{{ this.rideOverview['totalFourSeatersEarnings'] + this.rideOverview['totalSixSeatersEarnings'] |
                                                currency:'PHP' }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/payout.svg">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>4-Seater</small>
                                                    <h4>{{ this.rideOverview['totalFourSeatersEarnings'] |
                                                        currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>6-Seater</small>
                                                    <h4>{{ this.rideOverview['totalSixSeatersEarnings'] |
                                                        currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/suv.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-panel">
                                    <div class="card-item card-gray">
                                        <div class="card-content">
                                            <small>Total Okito Commissions</small>
                                            <h4>{{ this.rideOverview['totalFourSeaterssComissions'] + this.rideOverview['totalFourSeaterssComissions'] | currency:'PHP' }}</h4>
                                        </div>
                                        <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                                    </div>
                                    <div class="card-panel-item">
                                        <div class="card-panel-content">
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>From 4-Seater</small>
                                                    <h4>{{ this.rideOverview['totalFourSeaterssComissions'] | currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                            </div>
                                            <hr>
                                            <div class="card-item card-borderless">
                                                <div class="card-content">
                                                    <small>From 6-Seater</small>
                                                    <h4>{{ this.rideOverview['totalSixSeaterssComissions'] | currency:'PHP' }}</h4>
                                                </div>
                                                <img src="../../../../assets/okito/images/card-icons/suv.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                
            </div>
        </div>
    </div>
</div>
