<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Ads Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Ads Management</h2>
                </div>
                <hr>
                <div class="list-holder">
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="showCreateAdForm(createAds)">Create Ads</button>
                            <button class="btn btn-sm btn-default">Download</button>
                        </div>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Ads ID</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Create Date</th>
                                    <th>Published Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let ad of this.currentSelectedPageList">
                                        <td>{{ ad.id }}</td>
                                        <td>{{ ad.title }}</td>
                                        <td>{{ ad.subTitle }}</td>
                                        <td>{{ ad.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                        <td>{{ ad.publishedDate | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                        <td>
                                            <span class="stat stat-published" *ngIf="ad.status === 'published'">Published</span>
                                            <span class="stat stat-unpublished" *ngIf="ad.status === 'unpublished'">Unpublished</span>
                                            <span class="stat stat-draft" *ngIf="ad.status === 'draft'">Draft</span>
                                        </td>
                                        <td><a (click)="viewDetails(ad)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #showDetailComponent>
        <div class="main-holder">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><a (click)="this.returnToList()" style="cursor: pointer;">Ads Management</a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>Ad Details</span></li>
                        </ol>
                    </div>
                    <div class="profile-holder">
                        <div class="profile">
                            <div class="ads-img">
                                <img [src]="this.detailViewed['photo']" *ngIf="this.detailViewed['photo'] != ''">
                            </div>
                            <div class="profile-info">
                                <h4>{{ this.detailViewed['title'] }}</h4>
                                <div class="tags">
                                    <span class="stat stat-published" *ngIf="this.detailViewed['status'] === 'published'">Published</span>
                                    <span class="stat stat-unpublished" *ngIf="this.detailViewed['status'] === 'unpublished'">Unpublished</span>
                                    <span class="stat stat-draft" *ngIf="this.detailViewed['status'] === 'draft'">Draft</span>
                                </div>
                            </div>
                        </div>

                        <div class="profile-actions" *ngIf="this.enableEdit; else disableEdit">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="disableEditForm()">Cancel Edit</button>
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="updateThisDetails()">Save
                                Details</button>
                        </div>
                        
                        <ng-template #disableEdit>
                            <div class="profile-actions">
                                <button class="btn btn-sm btn-outline" *ngIf="this.detailViewed['status'] == 'unpublished' || this.detailViewed['status'] == 'draft'">Publish</button>
                                <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="enableEditForm()">Edit
                                    Ad</button>
                                <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                            </div>
                        </ng-template>
                    </div>
                    <div class="panel">
                        <h3>Ads Information</h3>
                        <div class="panel-info">
                            <div class="modal-upload-photo" *ngIf="enableEdit">
                                <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                                <div class="docs-holder">
                                    <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                                </div>
                                <div class="btn-photo">
                                    <label for="modal-input-file" class="btn btn-sm btn-outline">Upload New Photo</label>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Title</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['title']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Subtitle</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['subTitle']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Description</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['description']"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Art Card Link</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['link']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Publish Date</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['publishedDate']">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #createAds let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Create Ads</h5>
        <div class="modal-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="chk1" [(ngModel)]="this.newAdPublishToggle">
                <label class="form-check-label" for="chk1">Publish Now</label>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-form">
            <div class="form-item">
                <label class="form-label">Title</label>
                <input type="text" class="form-control" placeholder="Enter title" [(ngModel)]="adFormNew['title']">
            </div>
            <div class="form-item">
                <label class="form-label">Subtitle</label>
                <input type="text" class="form-control" placeholder="Enter subtitle" [(ngModel)]="adFormNew['subTitle']">
            </div>
            <div class="form-item">
                <label class="form-label">Description/Mechanics</label>
                <textarea class="form-control" placeholder="Type or paste your text here" [(ngModel)]="adFormNew['description']"></textarea>
            </div>
            <div class="form-item">
                <label class="form-label">Art Card (Photo)</label>
                <div class="modal-upload-photo">
                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                    <div class="docs-holder">
                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                    </div>
                    <div class="btn-photo">
                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                    </div>
                </div>
                <input type="text" class="form-control md-1" placeholder="Enter art card link" [(ngModel)]="adFormNew['link']">
            </div>
            <!-- <div class="form-item">
                <label class="form-label">Button Link</label>
                <input type="text" class="form-control" placeholder="Enter button link">
            </div> -->
    
            <div class="row publish-date">
                <div class="col-md-12">
                    <div class="form-item">
                        <label class="form-label">Publishing Date</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>    
            
            <div class="row publish-date">
                <div class="col-md-12">
                    <div class="form-item">
                        <label class="form-label" styl="margin-top:2em;">Publishing Time</label>
                        <div class="form-control-grp" style="align-items: center; justify-content: center; display: flex;">
                            <ngb-timepicker [(ngModel)]="time" [meridian]="meridian" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" id="publish" class="btn btn-default" (click)="createNewAd('', confirmPublish)">Save for Publishing</button>
        <button type="button" id="draft" class="btn btn-default" (click)="createNewAd('draft', confirmPublish)" [disabled]="this.newAdPublishToggle">Save as Draft</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Ad successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmPublish let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to create this Ad?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateAds(successCreate)">Yes, Create</button>
    </div>
</ng-template>