import { Component, TemplateRef, inject } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Consumer, Coupon, LocationData, City, Province} from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'app-dashboard-pricing-management',
  standalone: true,
  imports: [NgbDatepickerModule, NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, JsonPipe, NgbTimepickerModule, RouterModule ],
  templateUrl: './dashboard-pricing-management.component.html',
  styleUrl: './dashboard-pricing-management.component.css'
})
export class DashboardPricingManagementComponent {

}


