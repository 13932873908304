<div class="wrap">
    <header>
        <div class="service">
            <img src="./../../../assets/okito-comcenter/images/card-icons/express.svg" class="icon-size-56">
            <h2>Ok! Express</h2>
        </div>
        <div class="date">
            <img src="./../../../assets/okito-comcenter/images/date.svg">
            <small>{{ date | date:'dd-MM-yyyy' }}</small>
            <small>{{ date | date:'h:mm:ss a' }}</small>
        </div>
    </header>
    <div class="main-content">
        <div class="cards">
            <div class="row">
                <div class="col-md">
                    <div class="card-item card-gradient">
                        <div class="card-content">
                            <small>Total Bookings</small>
                            <h4>{{ this.overview.twoWheelsBookingCount + this.overview.fourWheelsBookingCount | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/users.svg" class="icon-size-64">
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-item outline-primary">
                        <div class="card-content">
                            <small>2-Wheels</small>
                            <h4>{{ this.overview.twoWheelsBookingCount | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/motorcycle.svg" class="icon-size-64">
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-item outline-secondary">
                        <div class="card-content">
                            <small>4-Wheels</small>
                            <h4>{{ this.overview.fourWheelsBookingCount | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/car.svg" class="icon-size-64">
                    </div>
                </div>
            </div>
        </div>
        <div class="cards">
            <div class="row">
                <div class="col-md">
                    <div class="card-panel">
                        <div class="card-item card-gray">
                            <div class="card-content">
                                <small>Pending Bookings</small>
                                <h4>{{ this.overview.pending.twoWheels + this.overview.pending.fourWheels | number: '1.0-0' }}</h4>
                            </div>
                            <img src="./../../../assets/okito-comcenter/images/card-icons/pending.svg" class="icon-size-56">
                        </div>
                        <div class="card-panel-item">
                            <div class="card-panel-content">
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>2-Wheels</small>
                                        <h4>{{ this.overview.pending.twoWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/motorcycle.svg" class="icon-size-56">
                                </div>
                                <hr>
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>4-Wheels</small>
                                        <h4>{{ this.overview.pending.fourWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/car.svg" class="icon-size-56">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-panel">
                        <div class="card-item card-gray">
                            <div class="card-content">
                                <small>Ongoing</small>
                                <h4>{{ this.overview.ongoing.twoWheels + this.overview.ongoing.fourWheels | number: '1.0-0' }}</h4>
                            </div>
                            <img src="./../../../assets/okito-comcenter/images/card-icons/ongoing.svg" class="icon-size-56">
                        </div>
                        <div class="card-panel-item">
                            <div class="card-panel-content">
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>2-Wheels</small>
                                        <h4>{{ this.overview.ongoing.twoWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/motorcycle.svg" class="icon-size-56">
                                </div>
                                <hr>
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>4-Wheels</small>
                                        <h4>{{ this.overview.ongoing.fourWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/car.svg" class="icon-size-56">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-panel">
                        <div class="card-item card-gray">
                            <div class="card-content">
                                <small>Completed</small>
                                <h4>{{ this.overview.completed.twoWheels + this.overview.completed.fourWheels | number: '1.0-0' }}</h4>
                            </div>
                            <img src="./../../../assets/okito-comcenter/images/card-icons/completed.svg" class="icon-size-56">
                        </div>
                        <div class="card-panel-item">
                            <div class="card-panel-content">
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>2-Wheels</small>
                                        <h4>{{ this.overview.completed.twoWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/motorcycle.svg" class="icon-size-56">
                                </div>
                                <hr>
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>4-Wheels</small>
                                        <h4>{{ this.overview.completed.fourWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/car.svg" class="icon-size-56">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-panel">
                        <div class="card-item card-gray">
                            <div class="card-content">
                                <small>Cancelled Bookings</small>
                                <h4>{{ this.overview.cancelled.twoWheels + this.overview.cancelled.fourWheels | number: '1.0-0' }}</h4>
                            </div>
                            <img src="./../../../assets/okito-comcenter/images/card-icons/canceled.svg" class="icon-size-56">
                        </div>
                        <div class="card-panel-item">
                            <div class="card-panel-content">
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>2-Wheels</small>
                                        <h4>{{ this.overview.cancelled.twoWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/motorcycle.svg" class="icon-size-56">
                                </div>
                                <hr>
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>4-Wheels</small>
                                        <h4>{{ this.overview.cancelled.fourWheels | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/car.svg" class="icon-size-56">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>