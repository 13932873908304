import { Component } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { RiderEarning, partnerEarningSummaryValues } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import {RouterModule} from '@angular/router';

@Component({
    selector: 'app-dashboard-rider-earnings',
    standalone: true,
    templateUrl: './dashboard-rider-earnings.component.html',
    styleUrl: './dashboard-rider-earnings.component.css',
    imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule],
})

export class DashboardRiderEarningsComponent {
    constructor(
        private dasher: DashboardService,
        private localservice: LocalService,
    ) { }
    
    public partnerEarningTabs: Record<string, any> = {
        orders: true,
        incentive: false,
        bonus: false,
        tip: false,
        withdraw: false
    };

    public riderList: RiderEarning[] = [];
    public filteredRiderList: RiderEarning[] = [];
    public searchKeyword: string = '';
    public currentSelectedPageList: RiderEarning[] = [];
    public maxPages: number = 0;
    public disableActionBtns: any = {
        next: false,
        prev: false,
    };
    public riderPages: number[] = [];
    public currentPage: number = 1;
    public pageSize: number = 15;
    
    public detailViewed: Record<string, any> = {};
    public showDetails: boolean = false;

    partnerSumamry: partnerEarningSummaryValues = {
        totalPartnerCommisions: 0,
        totalEarnings: 0,
        totalPayout: 0,
    }

    ngOnInit(): void {
        this.fetchPartnerEarningsByStatus();
    }

    fetchPartnerEarningsByStatus(status:string = 'all', action: string = ''){
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if

        this.dasher.tryFetchPartnerEarnings(status, this.currentPage).subscribe((res: any) => {
            if(res.length != 0){
                this.riderList = [];
                res.forEach((rider_isntance : any) => {
                    let i = rider_isntance;
                    this.riderList.push(i);
                });
                
                this.currentSelectedPageList = this.riderList;
                this.countPerPartnerStatus();
            }else{
                this.currentPage = fromPage;
            }//end if
        });
    }//end fn

    countPerPartnerStatus(){
        this.dasher.tryFetchEarningsSummary().subscribe((res: any) => {
            if(res.length != 0){
                this.partnerSumamry = res;
            }//end if
        });
    }
/* 
    setPageSelected(setPage: number){
        if(setPage > this.maxPages){
            this.currentPage = this.maxPages;
        }else{
            this.currentPage = setPage;
        }//end if

        if(this.currentPage == 1){
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.prev = false;
        }//end if

        if(this.currentPage == this.maxPages){
            this.disableActionBtns.next = true;
        }else{
            this.disableActionBtns.next = false;
        }//end if
        
        this.currentSelectedPageList = this.localservice.paginateData(this.filteredRiderList, this.currentPage, this.pageSize)
    }//end fn */

    nextPrev(action: string){
        var tabActive = '';
        
        switch(action){
            case 'next':
                this.fetchPartnerEarningsByStatus(tabActive , 'next');
            break;
  
            case 'prev':
                this.fetchPartnerEarningsByStatus(tabActive , 'prev');
            break;
        }//end swithc
    }//end fn


    searchData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;
  
        if (this.searchKeyword.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageList = this.riderList
        }else{
            this.currentSelectedPageList =  this.riderList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                )
            );
        }//end if
    }//end nfn

    viewDetails(details: RiderEarning){
        this.showDetails = true;
        this.detailViewed = details;
        console.log(details);
    }//end fn

    returnToList(){
        //this.setSelectedPageDataBasedOnStatus('all');
        this.showDetails = false;
        this.detailViewed = {};
  
        this.currentPage = 1;
        
        this.fetchPartnerEarningsByStatus();
    }//end fn

    showTabContents(tabindex: string){
        Object.keys(this.partnerEarningTabs).forEach((x) => {
            this.partnerEarningTabs[x] = false;
        });

        this.partnerEarningTabs[tabindex] = true;
    }//end
}
