import { Component, ViewEncapsulation } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';
import { CommonModule, JsonPipe } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

interface CommandCenterOkRideOverview {
  pending: RideCountBySeats;
  ongoing: RideCountBySeats;
  completed: RideCountBySeats;
  cancelled: RideCountBySeats;
}

interface RideCountBySeats {
  fourSeaterCount: number;
  sixSeaterCount: number;
}

@Component({
  selector: 'app-dashboard-comcenter-ride',
  standalone: true,
  imports:      [ CommonModule ],
  templateUrl: './dashboard-comcenter-ride.component.html',
  styleUrls: [
    './dashboard-comcenter-ride.component.scss',
    '../../../../assets/okito-comcenter/css/main.css',
    '../../../../assets/okito-comcenter/vendor/bootstrap/css/bootstrap.min.css',
    '../../../../assets/okito-comcenter/vendor/font-awesome/css/font-awesome.min.css'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})


export class DashboardComcenterRideComponent {
  private refreshInterval: any;
  date = new Date();

  constructor(
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) {
    // Update time every second
    setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnInit() {
    // Initial fetch
    this.fetchOverviewPageData();
    
    // Set up interval (30000ms = 30s)
    this.refreshInterval = setInterval(() => {
      this.fetchOverviewPageData();
    }, 30000);
  }

  ngOnDestroy() {
    // Clean up interval when component is destroyed
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public overview: CommandCenterOkRideOverview = {
    pending: { fourSeaterCount: 0, sixSeaterCount: 0 },
    ongoing: { fourSeaterCount: 0, sixSeaterCount: 0 },
    completed: { fourSeaterCount: 0, sixSeaterCount: 0 },
    cancelled: { fourSeaterCount: 0, sixSeaterCount: 0 }
  };

  
  fetchOverviewPageData() {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
        query commandCenterOkRideOverview {
          commandCenterOkRideOverview {
            pending {
              fourSeaterCount
              sixSeaterCount
            }
            ongoing {
              fourSeaterCount
              sixSeaterCount
            }
            completed {
              fourSeaterCount
              sixSeaterCount
            }
            cancelled {
              fourSeaterCount
              sixSeaterCount
            }
          }
        }           
        `
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['commandCenterOkRideOverview']){
            const overview = res.data['commandCenterOkRideOverview'];
            this.overview = overview;
            console.log(this.overview);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }
}
