import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStore } from '@store/auth.store';
import { map, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authStore: AuthStore,
    private router: Router
  ) {}

  canActivate() {
    return this.authStore.isAuthenticated$.pipe(
      filter(data => data !== undefined),
      take(1),
      map(data => {
        const isAuthorized = data.isAuthenticated && data.otpVerified;
        if (!isAuthorized) {
          this.router.navigate(['/admin/login']);
        }
        return isAuthorized;
      })
    );
  }
} 