<div class="wrap">
    <header>
        <div class="service">
            <img src="./../../../assets/okito-comcenter/images/card-icons/mototaxi.svg" class="icon-size-56">
            <h2>Ok! MotoTaxi</h2>
        </div>
        <div class="date">
            <img src="./../../../assets/okito-comcenter/images/date.svg">
            <small>{{ date | date:'dd-MM-yyyy' }}</small>
            <small>{{ date | date:'h:mm:ss a' }}</small>
        </div>
    </header>
    <div class="main-content">
        <div class="cards">
            <div class="row">
                <div class="col-md">
                    <div class="card-item card-gradient">
                        <div class="card-content">
                            <small>Total Bookings</small>
                            <h4>{{ this.overview.pending + this.overview.ongoing + this.overview.completed + this.overview.cancelled | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/users.svg" class="icon-size-64">
                    </div>
                </div>
            </div>
        </div>
        <div class="cards">
            <div class="row">
                <div class="col-md">
                    <div class="card-item card-gray">
                        <div class="card-content">
                            <small>Pending Bookings</small>
                            <h4>{{ this.overview.pending | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/pending.svg" class="icon-size-56">
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-item card-gray">
                        <div class="card-content">
                            <small>Ongoing</small>
                            <h4>{{ this.overview.ongoing | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/ongoing.svg" class="icon-size-56">
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-item card-gray">
                        <div class="card-content">
                            <small>Completed</small>
                            <h4>{{ this.overview.completed | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/completed.svg" class="icon-size-56">
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-item card-gray">
                        <div class="card-content">
                            <small>Cancelled Bookings</small>
                            <h4>{{ this.overview.cancelled | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/canceled.svg" class="icon-size-56">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>