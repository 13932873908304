<div class="wrap">
    <div class="login-grid">
        <div class="logo">
            <img src="../../../../assets/okito/images/okito-logo.svg">
        </div>
        <div class="login">
            <div class="update-success" *ngIf="this.verify_Status == 'success'">
                <img src="../../../../assets/okito/images/password-success.svg">
                <h2>Account has been verified successfully.</h2>
                <br>
                <span >Your can now proceed to login</span>
                <div class="login-grp" style="margin-top: 2em;">
                    <button (click)="gotoLogin()">Proceed to Login</button>
                </div>
            </div>

            <div class="update-success" *ngIf="this.verify_Status == 'failed'">
                <img src="../../../../assets/okito/images/password-success.svg">
                <h2>Account verification failed</h2>
                <br>
                <span>Please revisit link on your email and try again.</span>
            </div>
        </div>
</div>