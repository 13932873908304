import { Component , ViewEncapsulation } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';
import { CommonModule, JsonPipe } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

interface CommandCenterMotoTaxiOverview {
  pending: number;
  ongoing: number;
  completed: number;
  cancelled: number;
}

@Component({
  selector: 'app-dashboard-comcenter-motaxi',
  standalone: true,
  imports:      [ CommonModule ],
  templateUrl: './dashboard-comcenter-motaxi.component.html',
  styleUrls: [
    './dashboard-comcenter-motaxi.component.scss',
    '../../../../assets/okito-comcenter/css/main.css',
    '../../../../assets/okito-comcenter/vendor/bootstrap/css/bootstrap.min.css',
    '../../../../assets/okito-comcenter/vendor/font-awesome/css/font-awesome.min.css'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DashboardComcenterMotaxiComponent {
  private refreshInterval: any;
  date = new Date();
  
  constructor(
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) { 
     // Update time every second
     setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnInit() {
    // Initial fetch
    this.fetchOverviewPageData();
    
    // Set up interval (30000ms = 30s)
    this.refreshInterval = setInterval(() => {
      this.fetchOverviewPageData();
    }, 30000);
  }

  ngOnDestroy() {
    // Clean up interval when component is destroyed
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public overview: CommandCenterMotoTaxiOverview = {
    pending: 0,
    ongoing: 0,
    completed: 0,
    cancelled: 0
  };

  
  fetchOverviewPageData() {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
        query commandCenterMotoTaxiOverview {
          commandCenterMotoTaxiOverview {
            pending
            ongoing
            completed
            cancelled
          }
        }           
        `
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['commandCenterMotoTaxiOverview']){
            const overview = res.data['commandCenterMotoTaxiOverview'];
            this.overview = overview;
            console.log(this.overview);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }
}
