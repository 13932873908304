<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Location Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Location Management</h2>
                </div>
                <hr>
                <div class="list-holder">
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input type="text" name="" class="text-search" placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" data-bs-toggle="modal" data-bs-target="#create">Add
                                Location</button>
                        </div>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>City</th>
                                    <th>Province</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>001</td>
                                    <td>Pasig City</td>
                                    <td>NCR</td>
                                    <td class="c-toggle">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" checked>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button class="pager-prev"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button class="pager-next"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>