import { Component , TemplateRef} from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { NavComponent } from "../navigation/nav.component";
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Admin } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { FooterComponent } from '../footer/footer.component';
import {RouterModule} from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
    selector: 'app-dashboard-admins',
    standalone: true,
    templateUrl: './dashboard-admins.component.html',
    styleUrl: './dashboard-admins.component.css',
    imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule, MomentModule],
})

export class DashboardAdminsComponent {
    constructor(
        private router: Router,
        private dasher: DashboardService,
        private localservice: LocalService,
        private apollo: Apollo,
        private authStore: AuthStore,
        private toastr: ToastrService,
        private modalService: NgbModal
    ) { }

    public adminList: Admin[] = [];

    public searchKeyword: string = '';
    public currentSelectedPageList: Admin[] = [];
    public maxPages: number = 0;
    public disableActionBtns: any = {
        next: false,
        prev: false,
    };
    public riderPages: number[] = [];
    public currentPage: number = 1;
    public pageSize: number = 15;

    public detailViewed: Record<string, any> = {};
    public showDetails: boolean = false;

    adminForm = {
        name: '',
        email: '',
        accessLevel: 'admin' // default value
    };

    ngOnInit(): void {
        this.fetchAdmins();
       /*  this.dasher.tryFetchAdmins().subscribe((res: ServerResponse) => {
            if(res.status){
            res.body.forEach((consumer_instance : any) => {
                let i = consumer_instance;
                this.adminList.push(i);
            });

            this.maxPages = this.localservice.getTotalPages(this.adminList, this.pageSize);
            this.riderPages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
            this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
            
            if(this.maxPages == 1){
                this.disableActionBtns.next = true;
                this.disableActionBtns.prev = true;
            }else{
                this.disableActionBtns.next = false;
                this.disableActionBtns.prev = true;
            }//end if
            }//end fn
        }); */
    }

    /* setPageSelected(setPage: number){
        if(setPage > this.maxPages){
            this.currentPage = this.maxPages;
        }else{
            this.currentPage = setPage;
        }//end if
  
        if(this.currentPage == 1){
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.prev = false;
        }//end if
  
        if(this.currentPage == this.maxPages){
            this.disableActionBtns.next = true;
        }else{
            this.disableActionBtns.next = false;
        }//end if
        
        this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
    }//end fn */
  
    /* nextPrev(action: string){
        switch(action){
            case 'next':
                this.currentPage++
            break;
  
            case 'prev':
                this.currentPage--
            break;
        }//end swithc
  
        if(this.currentPage == 1){
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.prev = false;
        }//end if
  
        if(this.currentPage == this.maxPages){
            this.disableActionBtns.next = true;
        }else{
            this.disableActionBtns.next = false;
        }//end if
  
        this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
    }//end fn */

    nextPrev(action: string){
        /* var tabActive = '';
        Object.keys(this.couponTabs).forEach((x) => {
            if(this.couponTabs[x]){
                tabActive = x;
            }//end if
        }); */

        switch(action){
            case 'next':
                this.fetchAdmins('next');
            break;
  
            case 'prev':
                this.fetchAdmins('prev');
            break;
        }//end swithc
    }//end fn
  
  
    searchData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;
  
        if (this.searchKeyword.trim() === '') {
          console.log('Input is empty');
          this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
        }else{
            this.currentSelectedPageList =  this.adminList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                )
            );
        }//end if
    }

    viewDetails(details: Admin){
        this.showDetails = true;
        this.detailViewed = details;
        console.log(details);
    }//end fn

    returnToList(){
        this.showDetails = false;
        this.detailViewed = {};

        this.currentPage = 1;
        this.currentSelectedPageList = this.adminList;
    }//end fn

    fetchAdmins(action: string = '', searchQuery: string = '') {
        var fromPage = 1;

        if(action != '') {
            fromPage = this.currentPage;
            switch(action) {
                case 'next':
                    this.currentPage++
                    break;
                case 'prev':
                    if(this.currentPage != 1) {
                        this.currentPage--
                    }
                    break;
            }
        }

        this.apollo
            .watchQuery({
                fetchPolicy: 'network-only',
                query: gql`
                    query admins($page: Int, $querySearch: String) {
                        admins(page: $page, querySearch: $querySearch) {
                            adminId
                            name
                            email
                            dateCreated
                            status
                            isVerify
                            isNew
                            createdBy
                            dateUpdated
                        }
                    }
                `,
                variables: {
                    page: this.currentPage,
                    querySearch: searchQuery
                }
            }).valueChanges.subscribe({
                next: (res: any) => {
                    if (res.data && res.data['admins'] && res.data['admins'].length !== 0) {
                        this.adminList = res.data['admins'];
                        this.currentSelectedPageList = this.adminList;
                    } else {
                        this.currentPage = fromPage;
                    }
                },
                error: (error) => {
                    if (error.message === 'Unauthorized' || 
                        (error.graphQLErrors && 
                         error.graphQLErrors[0]?.extensions?.status === 403)) {
                        this.authStore.logout().subscribe(() => {
                            // Optional: Add any additional logout handling here
                            this.router.navigate(['/admin/login']);
                        });
                    }
                }
            });
    }

    showCreateAdminModal(modal: TemplateRef<any>){
        this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
    }

    createAdminData() {
        if (!this.adminForm.name || !this.adminForm.email || !this.adminForm.accessLevel) {
            this.toastr.error('Please fill in all required fields');
            return;
        }

        const input = {
            name: this.adminForm.name,
            email: this.adminForm.email,
            accessLevel: this.adminForm.accessLevel
        };

        this.apollo.mutate({
            mutation: gql`
                mutation createAdmin($createAdminInput: CreateAdminInput!) {
                    createAdmin(createAdminInput: $createAdminInput) {
                        adminId
                        name
                        email
                        dateCreated
                        status
                        isVerify
                        isNew
                        createdBy
                        dateUpdated
                    }
                }
            `,
            variables: {
                createAdminInput: input
            }
        }).subscribe({
            next: ({ data }: any) => {
                this.toastr.success('Admin created successfully');
                this.modalService.dismissAll();
                this.adminForm = { name: '', email: '', accessLevel: 'admin' }; // Reset form
                this.fetchAdmins(); // Refresh the list
            },
            error: (error) => {
                if (error.message === 'Unauthorized' || 
                    (error.graphQLErrors && 
                     error.graphQLErrors[0]?.extensions?.status === 403)) {
                    this.authStore.logout().subscribe(() => {
                        // Optional: Add any additional logout handling here
                        this.router.navigate(['/admin/login']);
                    });
                } else {
                    this.toastr.error(error.message || 'Error creating admin');
                }
            }
        });
    }
}
