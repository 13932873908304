<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>News and Events Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>News and Events Management</h2>
                </div>
                <hr>
                <div class="list-holder">
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text"
                                class="text-search" placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="showCreateAdForm(createNewsEvents)">Create News / Events</button>
                            <button class="btn btn-sm btn-default">Download</button>
                        </div>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>News ID</th>
                                    <th>Audience</th>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let ne of this.currentSelectedPageList">
                                        <td>{{ ne.id }}</td>
                                        <td>{{ ne.audience[0].toUpperCase() + ne.audience.slice(1) }}</td>
                                        <td>{{ ne.type[0].toUpperCase() + ne.type.slice(1) }}</td>
                                        <td>{{ ne.title }}</td>
                                        <td>{{ ne.subTitle }}</td>
                                        <td>{{ ne.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                        <td>
                                            <span class="stat stat-published" *ngIf="ne.status === 'published'">Published</span>
                                            <span class="stat stat-unpublished" *ngIf="ne.status === 'unpublished'">Unpublished</span>
                                            <span class="stat stat-draft" *ngIf="ne.status === 'draft'">Draft</span>
                                        </td>
                                        <td><a (click)="viewDetails(ne)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                     
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #showDetailComponent>
        <div class="main-holder">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><a (click)="this.returnToList()" style="cursor: pointer;">News and Event Management</a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>News and Event Details</span></li>
                        </ol>
                    </div>
                    <div class="profile-holder">
                        <div class="profile">
                            <div class="ads-img">
                                <img [src]="this.detailViewed['photo']" *ngIf="this.detailViewed['photo'] != ''">
                            </div>
                            <div class="profile-info">
                                <h4>{{ this.detailViewed['title'] }}</h4>
                                <div class="tags">
                                    <span class="stat stat-published" *ngIf="this.detailViewed['status']">Published</span>
                                    <span class="stat stat-unpublished" *ngIf="this.detailViewed['status'] === 'unpublished'">Unpublished</span>
                                    <span class="stat stat-draft" *ngIf="this.detailViewed['status'] === 'draft'">Draft</span>
                                </div>
                            </div>
                        </div>

                        <div class="profile-actions" *ngIf="this.enableEdit; else disableEdit">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="disableEditForm()">Cancel Edit</button>
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="updateThisDetails()">Save
                                Details</button>
                        </div>
                        
                        <ng-template #disableEdit>
                            <div class="profile-actions">
                                <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                                    style="margin-right: .5em;">View Attachment</button>
                                <button class="btn btn-sm btn-outline"
                                    *ngIf="this.detailViewed['status'] == 'unpublished' || this.detailViewed['status'] == 'draft'">Publish</button>
                                <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="enableEditForm()">Edit
                                    News / Event</button>
                                <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                            </div>
                        </ng-template>
                    </div>
                    <div class="panel">
                        <h3>News and Event Information</h3>
                        <div class="modal-upload-photo" *ngIf="enableEdit">
                            <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                            <div class="docs-holder">
                                <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                            </div>
                            <div class="btn-photo">
                                <label for="modal-input-file" class="btn btn-sm btn-outline">Upload New Photo</label>
                            </div>
                        </div>
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Title</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['title']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Subtitle</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['subTitle']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Audience</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [(ngModel)]="this.detailViewed['audience']" [disabled]="!enableEdit">
                                            <option value="all">All</option>
                                            <option value="consumer">Consumer</option>
                                            <option value="partner">Partner</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Type</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [(ngModel)]="this.detailViewed['type']"
                                            [disabled]="!enableEdit">
                                            <option value="internal">Internal</option>
                                            <option value="external">External</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Description</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['description']"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Link</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['link']">
                                    </div>
                                </div>
                            </div>

                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Status</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled [(ngModel)]="this.detailViewed['status']">
                                    </div>
                                </div>
                            </div>

                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Location</label>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-md">
                                                <select (change)="onSelectProvince($event)" class="form-select" aria-label="Default select example"
                                                    [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['province']">
                                                    <option [value]="selectedProvince.provDesc" [attr.data-provcode]="selectedProvince.provCode">{{
                                                        selectedProvince.provDesc }}</option>
                                                    <option *ngFor="let province of this.locationData.provinces" [value]="province.provDesc"
                                                        [attr.data-provcode]="province.provCode">{{ province.provDesc }}</option>
                                                </select>
                                            </div>
                                            <div class="col-md">
                                                <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit"
                                                    [(ngModel)]="this.detailViewed['city']">
                                                    <option [value]="selectedCity.citymunDesc" [attr.data-citycode]="selectedCity.citymunCode">{{
                                                        selectedCity.citymunDesc }}</option>
                                                    <option *ngFor="let city of this.filteredCities" [value]="city.citymunDesc"
                                                        [attr.data-citycode]="city.citymunCode">
                                                        {{ city.citymunDesc }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #createNewsEvents let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Create News / Event</h5>
        <div class="modal-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="chk1" [(ngModel)]="this.newPublishToggle">
                <label class="form-check-label" for="chk1">Publish Now</label>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-form">
            <div class="form-item">
                <label class="form-label">Title</label>
                <input type="text" class="form-control" placeholder="Enter title" [(ngModel)]="NEFormNew['title']">
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Subtitle</label>
                        <input type="text" class="form-control" placeholder="Enter subtitle" [(ngModel)]="NEFormNew['subTitle']">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Type</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="this.NEFormNew['type']">
                            <option value="internal">Internal</option>
                            <option value="external">External</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Description/Mechanics</label>
                <textarea class="form-control" placeholder="Type or paste your text here"
                    [(ngModel)]="NEFormNew['description']"></textarea>
            </div>
            <div class="form-item">
                <label class="form-label">Art Card (Photo)</label>
                <div class="modal-upload-photo">
                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)"
                        accept="image/*">
                    <div class="docs-holder">
                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                    </div>
                    <div class="btn-photo">
                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                    </div>
                </div>
                <input type="text" class="form-control md-1" placeholder="Enter art card link"
                    [(ngModel)]="NEFormNew['link']">
            </div>
            
            <label>ADDITIONAL FILTERS</label>
            <div class="form-item">
                <label class="form-label">Audience</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="this.NEFormNew['audience']">
                    <option value="all">All</option>
                    <option value="consumer">Consumers</option>
                    <option value="partner">Partners</option>
                </select>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Location</label>
                        <select (change)="onSelectProvince($event)" class="form-select" aria-label="Default select example"
                            [(ngModel)]="NEFormNew['province']">
                            <option *ngFor="let province of this.locationData.provinces" [value]="province.provDesc"
                                [attr.data-provcode]="province.provCode">{{ province.provDesc }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">&nbsp;</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="NEFormNew['city']">
                            <option *ngFor="let city of this.filteredCities" [value]="city.citymunDesc"
                                [attr.data-citycode]="city.citymunCode">{{ city.citymunDesc }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" id="publish" class="btn btn-default" (click)="createNE('', confirmPublish)">Save for
            Publishing</button>
        <button type="button" id="draft" class="btn btn-default" (click)="createNE('draft', confirmPublish)"
            [disabled]="this.newPublishToggle">Save as Draft</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>News / Event is successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmPublish let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to create this News/Event?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateNews(successCreate)">Yes, Create</button>
    </div>
</ng-template>

<ng-template #viewImageLarger let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Attachment Viewing</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <div class="image-bigviewer">
            <img class="flex-item" [src]="this.detailViewed['photo']" *ngIf="this.detailViewed['photo'] != ''"
                style="height: 80vh;">
        </div>
    </div>
    <div class="modal-footer">
        <div style="width: 25vh;">
            <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Close</button>
        </div>
    </div>
</ng-template>