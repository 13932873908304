import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class LocalService {  
    constructor() { }

    paginateData<T>(
        array: T[], 
        pageNumber: number, 
        pageSize: number
        ): T[] {
        const startIndex = (pageNumber - 1) * pageSize;
        return array.slice(startIndex, startIndex + pageSize);
    }

    getTotalPages<T>(array: T[], pageSize: number): number {
        return Math.ceil(array.length / pageSize);
    }
}
