import { Component, TemplateRef, inject } from '@angular/core';
//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { NewsItem, LocationData, City, Province } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal,NgbAlertModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { MomentModule } from 'ngx-moment';

  @Component({
      selector: 'app-dashboard-news-events',
      standalone: true,
      templateUrl: './dashboard-news-events.component.html',
      styleUrl: './dashboard-news-events.component.css',
      imports: [NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, JsonPipe, RouterModule, MomentModule],
  })

  export class DashboardNewsEventsComponent {
    constructor(
      private dasher: DashboardService,
      private localservice: LocalService,
  ) { }

  imageSrc?: string | ArrayBuffer | null; 

  private modalService = inject(NgbModal);
  locationData: LocationData = {
      provinces: [],
      cities: [],
  };

  selectedProvinceCode: string = '';
  filteredCities: City[] = [];
  selectedProvince: Province = {
      id: 0,
      provCode: '',
      provDesc: '',
      psgcCode: '',
      regCode: '',
  };
  selectedCity: City = {
      citymunCode: '',
      citymunDesc: '',
      id: 0,
      provCode: '',
      psgcCode: '',
      regDesc: '',
  };

  public NEList: NewsItem[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: NewsItem[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public NEPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public detailViewed: Record<string, any> = {};
  public showDetails: boolean = false;
  public NEFormNew: Record<string, any> = {
    title: '',
    subTitle: '',
    type: '',
    description: '',
    photo: '',
    link: '',
    audience: '',
    location: '',
    city: '',
    status: '',
  }
  public newPublishToggle: boolean = false;
  NEPhoto: File | null = null;

  public enableEdit: boolean = false;

  ngOnInit(): void {
    this.fetchNews('all')
    this.populateLocationDropdowns();
  }

  fetchNews(action: string = ''){
    var fromPage = 1;

    if(action != ''){
        fromPage = this.currentPage;
        switch(action){
            case 'next':
                this.currentPage++
            break;
  
            case 'prev':
                if(this.currentPage != 1){
                    this.currentPage--
                }//end if
            break;
        }//end swithc    
    }//end if

    this.dasher.tryFetchNewsAndEvents(this.currentPage).subscribe((res: any) => {
        if(res.length != 0){
            this.NEList = [];
            res.forEach((news_instance : any) => {
                let i = news_instance;
                this.NEList.push(i);
            });

            this.currentSelectedPageList = this.NEList;
        }else{
            this.currentPage = fromPage;
        }//end if
    });
  }//end fn

  nextPrev(action: string){
    switch(action){
        case 'next':
            this.fetchNews('next');
        break;

        case 'prev':
            this.fetchNews('prev');
        break;
    }//end swithc
  }//end fn

  searchData(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchKeyword = inputElement.value;

    if (this.searchKeyword.trim() === '') {
        console.log('Input is empty');
        this.currentSelectedPageList = this.NEList
    }else{
        this.currentSelectedPageList =  this.NEList.filter(object =>
            Object.values(object).some(value => 
                (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
            )
        );
    }//end if
  }//end nfn

  viewDetails(details: NewsItem){
    this.showDetails = true;
    this.detailViewed = details;

    var cityData = this.locationData.cities.filter((x) => x.citymunDesc == details.city);
    var provinceData = this.locationData.provinces.filter((x) => x.provDesc == details.province);
    
    if(cityData.length != 0){
        this.selectedCity = cityData[0];
    }//emd

    if(provinceData.length != 0){
        this.selectedProvince = provinceData[0];
    }//emd
  }//end fn

  returnToList(){
      this.showDetails = false;
      this.detailViewed = {};

      this.currentPage = 1;
      this.currentSelectedPageList = this.NEList;
  }//end fn

  showCreateAdForm(modal: TemplateRef<any>){
    this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
  }//end fn

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader(); 
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(file);
      this.NEPhoto = file;
    }//end
  }//end fn

  onSelectProvince(event: any) {
    const selectedIndex = event.target.selectedIndex;
    const provcode = event.target.options[selectedIndex].getAttribute('data-provcode');
    
    this.filteredCities = this.locationData.cities.filter((x) => x.provCode == provcode);
    console.log(this.locationData.cities);
  }

  createNE(status: string, confirmModal: TemplateRef <any>){
    if(status == 'draft'){
      this.NEFormNew['status'] = status;
    }else{
      if(this.newPublishToggle){
        this.NEFormNew['status'] = 'unpublished';
      }else{
        this.NEFormNew['status'] = 'published';
      }//end fn
    }//end if

    var errors: number = 0;
    Object.keys(this.NEFormNew).forEach((x) => {
      if(x != 'photo'){
        if(this.NEFormNew[x] == ''){
          errors ++;
        }//end if
      }//end if
    });

    if(errors != 0){
      alert('Please enter all required fields');
    }else{
      this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
    }//end if
  }//end fn

  proceedCreateNews(successModal: TemplateRef<any>){
    const formData:any = new FormData();      
    if (this.NEPhoto) {
      formData.append('file', this.NEPhoto, this.NEPhoto.name);
    }
    
    this.dasher.uploadPhoto(formData, 'news_photo').subscribe((res: any) => {
      this.NEFormNew['photo'] = res.photo;
      this.dasher.createNewsOrEvent(this.NEFormNew).subscribe((res: any) => {
        if(res.length != 0){
          this.currentPage = 1;
          this.NEList = [res, ...this.NEList];
          this.maxPages = this.localservice.getTotalPages(this.NEList, this.pageSize);
          this.NEPages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
          this.currentSelectedPageList = this.localservice.paginateData(this.NEList, this.currentPage, this.pageSize)
          
          if(this.maxPages == 1){
              this.disableActionBtns.next = true;
              this.disableActionBtns.prev = true;
          }else{
              this.disableActionBtns.next = false;
              this.disableActionBtns.prev = true;
          }//end if

          this.modalService.dismissAll();
          Object.keys(this.NEFormNew).forEach((x) => {
            this.NEFormNew[x] = '';
          });
          
          this.imageSrc = null;
          this.NEPhoto = null;
          this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
      });
    });
  }//end fn
  
  closeAllModals(){
    this.modalService.dismissAll();
  }//end fn

  enableEditForm(){
    this.enableEdit = true;
  }//end fn

  disableEditForm(){
    this.enableEdit = false;
  }//end fn

  updateThisDetails(){
    const formData:any = new FormData();      
    if (this.NEPhoto) {
        formData.append('file', this.NEPhoto, this.NEPhoto.name);
        this.dasher.uploadPhoto(formData, 'news_photo').subscribe((res: any) => {
            this.detailViewed['photo'] = res.photo;
            this.dasher.updateNews(this.detailViewed).subscribe((res) => {
              alert(res.message);
              this.disableEditForm();
              this.NEPhoto = null;
            });
        });
        
    }else{
      this.dasher.updateNews(this.detailViewed).subscribe((res) => {
        alert(res.message);
        this.disableEditForm();
      });
    }//end if
  }//end fn

  populateLocationDropdowns(){
    this.dasher.tryFetchLocations().subscribe((res: any) => {
        if(res.length != 0){
            res.forEach((location_instance : any) => {
                this.locationData.provinces.push(location_instance['province']);
                location_instance['cities'].forEach((city_instance: any) => {
                    this.locationData.cities.push(city_instance);
                });

                this.filteredCities = this.locationData.cities;
            });
        }//end if
    });
  }//end fn

  viewLargerModal(modalLarger: TemplateRef<any>){
    this.modalService.open(modalLarger, {fullscreen:true, size: 'xl',  backdrop: 'static'});
  }//endd fn
}
