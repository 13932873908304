<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Withdrawal Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Withdrawal Management</h2>
                </div>
                <hr>
                <div class="list-holder">
                    <div class="tools">
                        <div class="search mg-right-auto">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                    </div>
                    <div class="list-tabs">
                        <ul>
                            <li [ngClass]="{'active': this.withdrawTabs['for_review']}"><a (click)="filterWithdrawalRequest('for_review')" style="cursor: pointer;">For Review</a></li>
                            <li [ngClass]="{'active': this.withdrawTabs['processing']}"><a (click)="filterWithdrawalRequest('processing')" style="cursor: pointer;">Processing</a></li>
                            <li [ngClass]="{'active': this.withdrawTabs['completed']}"><a (click)="filterWithdrawalRequest('completed')" style="cursor: pointer;">Completed Payout</a></li>
                            <li [ngClass]="{'active': this.withdrawTabs['cancelled']}"><a (click)="filterWithdrawalRequest('cancelled')" style="cursor: pointer;">Cancelled</a></li>
                        </ul>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Ref No.</th>
                                    <th>Date</th>
                                    <th>Partner</th>
                                    <th>Payment Method</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let withdraw of this.currentSelectedPageList">
                                        <td>{{ withdraw.partnerWalletRequestId }}</td>
                                        <td>{{ withdraw.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                        <td>{{ withdraw.partner.firstName + ' ' + withdraw.partner.lastName}}</td>
                                        <td class="list-account-number">
                                            <span>{{ withdraw.bankName }}</span>
                                            <small>{{ withdraw.accountName }}</small>
                                        </td>
                                        <td>{{ withdraw.amount | currency:'PHP' }}</td>
                                        <td>
                                            <span class="stat stat-pending" *ngIf="withdraw.status == 'for_review'">For Review</span>
                                            <span class="stat stat-active" *ngIf="withdraw.status == 'processing'">Processing</span>
                                            <span class="stat stat-approved" *ngIf="withdraw.status == 'completed'">Completed</span>
                                            <span class="stat stat-amend" *ngIf="withdraw.status == 'cancelled'">Cancelled</span>
                                        </td>
                                        <td *ngIf="withdraw.status == 'for_review' || withdraw.status == 'processing'">
                                            <button *ngIf="withdraw.status == 'for_review'"
                                                (click)="processWithdrawalAction('cancel', withdraw, confirmModal)" class="btn btn-sm btn-outline"
                                                style="margin-right: 5px;">Cancel</button>
                                            <button *ngIf="withdraw.status == 'for_review'"
                                                (click)="processWithdrawalAction('process', withdraw, confirmModal)"
                                                class="btn btn-sm btn-outline">Process</button>
                                            <button *ngIf="withdraw.status == 'processing'"
                                                (click)="processWithdrawalAction('complete', withdraw, confirmModal)"
                                                class="btn btn-sm btn-outline">Complete Payout</button>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button (click)="nextPrev('next')"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #sucessModal let-modal class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>{{ processWithdrawalModals['successStr'] }}</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmModal let-modal class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>{{ processWithdrawalModals['confirmStr'] }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedWithdrawalProcess(sucessModal)">Yes, Proceed</button>
    </div>
</ng-template>