import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { loginForm, forgotPasswordForm } from './../interfaces/auth.interfaces';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(
    private http: HttpClient,
    private cooker: CookieService,
    private router: Router
  ) { }

  tryAuthenticate(formData: loginForm): Observable<any> {
    let url = `${environment.apiUrl}auth/admin/login`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer YourAccessToken');
      
    return this.http.post<any>(`${url}`, formData, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryValidateAuthOTP(formData: loginForm): Observable<any> {
    let url = `${environment.apiUrl}auth/admin/login/verify`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer YourAccessToken');
      
    return this.http.post<any>(`${url}`, formData, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryResetPassword(email: string): Observable<any> {
    let url = `${environment.apiUrl}auth/admin/password_reset`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer YourAccessToken');
      
    return this.http.post<any>(`${url}`, { 'email': email }, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryUpdatingPassword(forgot_passform: forgotPasswordForm): Observable<any> {
    let url = `${environment.apiUrl}auth/admin/password_reset/verify`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer YourAccessToken');
      
    return this.http.post<any>(`${url}`, forgot_passform, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn


  tryResendEmailValidationLink(email: string): Observable<any> {
    let url = `${environment.apiUrl}auth/admin/resend_email_validation`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer YourAccessToken');
      
    return this.http.post<any>(`${url}`, { 'email': email }, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  tryResendAuthOTP(email: string): Observable<any> {
    let url = `${environment.apiUrl}auth/admin/resend_auth_otp`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer YourAccessToken');
      
    return this.http.post<any>(`${url}`, { 'email': email }, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }//end fn

  logoutAccount(){
    this.cooker.delete(environment.tokenName);
    this.cooker.delete(environment.userDetailsTokenName);
    this.router.navigate(['/admin/login']);
  }//end fn

  private handleError(error: any): Observable<any> {
    console.error('An error occurred:', error);
    throw throwError(error);  
  }
}
