<div class="missing-holder">
    <div class="logo">
        <img src="../../../../assets/okito/images/okito-logo.svg">
    </div>

    <div class="missing">
        <img src="../../../../assets/okito/images/404.svg">
        <h2>404</h2>
        <small>Error. Page not found.</small>
        <span class="btn btn-sm btn-default" (click)="gotoHome();" style="cursor:pointer;">Back to Homepage</span>
    </div>
</div>