<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Notification</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Notification</h2>
                </div>
                <hr>

                <div class="list-holder">
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" (click)="showCreateNotif(createNotif)">Create
                                Notification</button>
                        </div>
                    </div>
                    <div class="list-tabs">
                        <ul>
                            <li class="active"><a>Announcement</a></li>
                            <li><a>Promo</a></li>
                        </ul>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <!--<th>Date</th>-->
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let notif of this.currentSelectedPageList">
                                        <td>{{ notif.id}}</td>
                                        <td>{{ notif.title }}</td>
                                        <td>{{ notif.subTitle }}</td>
                                        <td>
                                            <span class="stat stat-published" *ngIf="notif.type == 'announcement'">Announcement</span>
                                            <span class="stat stat-unpublished" *ngIf="notif.type == 'promo'">Promo</span>
                                        </td>
                                        <!-- <td>{{ notif. | date: 'yyyy-MM-dd HH:mm:ss' }}</td>-->
                                        <td><a (click)="viewDetails(notif)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button (click)="nextPrev('next')"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #showDetailComponent>
        <div class="main-holder">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><a (click)="this.returnToList()" style="cursor: pointer;">Notification</a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>Notification Details</span></li>
                        </ol>
                    </div>
                    <div class="profile-holder">
                        <div class="profile">
                            <div class="ads-img">
                                <img [src]="this.detailViewed['photo']" *ngIf="this.detailViewed['photo'] != ''">
                            </div>
                            <div class="profile-info">
                                <h4>{{ this.detailViewed['title'] }}</h4>
                                <!--<div style="display: block;">
                                    <h6 style="font-size: 14px;">Published Date: {{ this.detailViewed['dateCreated'] | date: 'yyyy-MM-dd HH:mm:ss' }}</h6>
                                    <h6 style="font-size: 14px;">Last Updated Date: {{ this.detailViewed['dateUpdated'] | date: 'yyyy-MM-dd HH:mm:ss' }}</h6>
                                </div>-->
                                <div class="tags">
                                    <span class="stat stat-published" *ngIf="this.detailViewed['type'] == 'announcement'">Announcement</span>
                                    <span class="stat stat-unpublished" *ngIf="this.detailViewed['type'] == 'promo'">Promo</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="profile-actions">
                            <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                        </div>
                    </div>
                    <div class="panel">
                        <h3>Notification Information</h3>
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Title</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['title']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Subtitle</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['subTitle']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Type</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['type']">
                                            <option value="announcement">Announcement</option>
                                            <option value="promo">Promo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Service Area</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['serviceArea']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Description</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['description']"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Usertype</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['userType']">
                                            <option value="all">All</option>
                                            <option value="consumer">Consumer</option>
                                            <option value="partner">Partners</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #createNotif let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Create Ads</h5>
    </div>
    <div class="modal-body">
        <div class="modal-form">
            <div class="form-item">
                <label class="form-label">Title</label>
                <input type="text" class="form-control" placeholder="Enter title" [(ngModel)]="this.notifForm['title']">
            </div>
            <div class="form-item">
                <label class="form-label">Subtitle</label>
                <input type="text" class="form-control" placeholder="Enter subtitle" [(ngModel)]="this.notifForm['subTitle']">
            </div>
            <div class="form-item">
                <label class="form-label">Type</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="this.notifForm['type']">
                    <option value="announcement">Announcement</option>
                    <option value="promo">Promo</option>
                </select>
            </div>
            <div class="form-item">
                <label class="form-label">Description/Mechanics</label>
                <textarea class="form-control" placeholder="Type or paste your text here"
                    [(ngModel)]="this.notifForm['description']"></textarea>
            </div>
            <div class="form-item">
                <label class="form-label">Art Card (Photo)</label>
                <div class="modal-upload-photo">
                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                    <div class="docs-holder">
                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                    </div>
                    <div class="btn-photo">
                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                    </div>
                </div>
            </div>
            <label>ADDITIONAL FILTERS</label>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Service Area</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="this.notifForm['serviceArea']">
                            <option value="ncr">NCR</option>
                            <option value="batangas">Batangas</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item form-error">
                        <label class="form-label">Usertype</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="this.notifForm['userType']">
                            <option value="all">All</option>
                            <option value="consumer">Consumer</option>
                            <option value="partner">Partner</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-outline" (click)="clearForm()">Clear Form</button>
        <button type="button" id="publish" class="btn btn-default" (click)="createNotification(confirmPublish)">Create
            Notification</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Notification successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmPublish let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to create this Notification</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateNotification(successCreate)">Yes, Create</button>
    </div>
</ng-template>