import { Component } from '@angular/core';
import { NavComponent } from "../navigation/nav.component";
import { HeaderComponent } from "../header/header.component";
import {RouterModule} from '@angular/router';
@Component({
    selector: 'app-dashboard-location',
    standalone: true,
    templateUrl: './dashboard-location.component.html',
    styleUrl: './dashboard-location.component.css',
    imports: [NavComponent, HeaderComponent, RouterModule]
})
export class DashboardLocationComponent {

}
