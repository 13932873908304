import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { HttpService } from '@services/http.service';
import { Apollo, gql } from 'apollo-angular';


//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';

import { CommonModule, JsonPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@envs/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { isPlatformBrowser } from '@angular/common';

import { DashboardService } from '@services/dashboard.service';
import { ServerResponse } from '@interfaces/general.interface';
import { Rider, Consumer } from '@interfaces/models.interface';
import {RouterModule} from '@angular/router';
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMap} from '@angular/google-maps';
import { AuthStore } from '@store/auth.store';

@Component({
  selector: 'app-dashboard-home',
  standalone: true,
  imports:      [ CommonModule, HeaderComponent, NavComponent, FooterComponent, RouterModule , NgbDatepickerModule, NgbAlertModule, NgbTimepickerModule, FormsModule, GoogleMapsModule, GoogleMap],
  templateUrl: './dashboard-home.component.html',
  styleUrl: './dashboard-home.component.css'
})

export class DashboardHomeComponent {
  today = new Date();
  model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };

  constructor(
    private httpService: HttpService,
    private dasher: DashboardService,
    private cooker: CookieService,
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) { 
  }

  public consumerList: Consumer[] = [];
  public riderList: Rider[] = [];
  
  homepageSummary: Record<string, any> = {
    totalUser: 0,
    totalDistance: 0,
    totalPartner: 0,
    totalPartnerEarnings: 0,
    totalComissions: 0,
    rideHailingTwoWheelsCount: 0,
    rideHailingFourWheelsCount: 0,
    expressTwoWheelsCount: 0,
    expressFourWheelsCount: 0,
    totalApprovedTwowheels: 0,
    totalApprovedFourWheels: 0
  }
  
  polyLinePath: google.maps.LatLng[] = [];
  display: any;
  center: google.maps.LatLngLiteral = {
      lat: 22.2736308,
      lng: 70.7512555
  };
  zoom = 15;
  polyLineOptions: google.maps.PolylineOptions = {
    strokeColor: '#D81960',
    strokeOpacity: 1.0,
    strokeWeight: 5,
    clickable: false,
    editable: false,
    visible: true,
    geodesic: true, // Smoothen the edges
  };

  public homeTabs: Record<string, any> = {
    overview: true,
    express: false,
    mototaxi: false,
    ride: false,
  }
  
  public expressOverview: Record<string, any> = {
    twoWheelsPartnerCount: 0,
    twoWheelsBookingCount: 0,
    totalTwoWheelsEarnings: 0,
    totalTwoWheelsComissions: 0,
    fourWheelsPartnerCount: 0,
    fourWheelsBookingCount: 0,
    totalFourWheelsEarnings: 0,
    totalFourWheelsComissions: 0
  }
  
  public rideOverview: Record<string, any> = {
    twoSeatersPartnerCount: 0,

    fourSeatersPartnerCount: 0,
    sixSeatersPartnerCount: 0,

    twoSeatersBookingCount: 0,

    fourSeatersBookingCount: 0,
    sixSeatersBookingCount: 0,

    totalTwoSeatersEarnings: 0,

    totalFourSeatersEarnings: 0,
    totalSixSeatersEarnings: 0,

    totalTwoSeaterssComissions: 0,
    
    totalFourSeaterssComissions: 0,
    totalSixSeaterssComissions: 0
  }
  
  ngOnInit(): void {
    this.fetchOverview();
    this.fetchExpressOverview();
  }  


  setTabPanelActive(panelIndex: string){
    Object.keys(this.homeTabs).forEach((x) => {
        this.homeTabs[x] = false;
    });

    this.homeTabs[panelIndex] = true;

    if(panelIndex === 'mototaxi'){
      this.fetchRideOverview('mototaxi');
    }

    if (panelIndex === 'ride'){
      this.fetchRideOverview('okride');
    }
}//end fn
  
  /*------------------------------------------
  --------------------------------------------
  moveMap()
  --------------------------------------------
  --------------------------------------------*/
  moveMap(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  /*------------------------------------------
  --------------------------------------------
  move()
  --------------------------------------------
  --------------------------------------------*/
  move(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  fetchOverview() {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
        query dashboardOverview {
          dashboardOverview {
            totalUser
            totalDistance
            totalPartner
            totalPartnerEarnings
            totalComissions
            rideHailingTwoWheelsCount
            rideHailingFourWheelsCount
            expressTwoWheelsCount
            expressFourWheelsCount
            totalApprovedTwowheels
            totalApprovedFourWheels
          }
        }           
        `,
        variables: {}
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['dashboardOverview']){
            const overview = res.data['dashboardOverview'];
             // Update direct mappings
            Object.keys(this.homepageSummary).forEach((key) => {
              this.homepageSummary[key] = overview[key];
            });

            console.log(this.homepageSummary);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }

  fetchExpressOverview() {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
        query dashboardExpressOverview {
          dashboardExpressOverview {
            twoWheelsPartnerCount
            fourWheelsPartnerCount
            twoWheelsBookingCount
            fourWheelsBookingCount
            totalTwoWheelsEarnings
            totalFourWheelsEarnings
            totalTwoWheelsComissions
            totalFourWheelsComissions
          }
        }           
        `,
        variables: {}
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['dashboardExpressOverview']){
            const overview = res.data['dashboardExpressOverview'];
            // Update direct mappings
            Object.keys(this.expressOverview).forEach((key) => {
              this.expressOverview[key] = overview[key];
            });

            console.log(this.expressOverview);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }

  fetchRideOverview(service: string) {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
        query dashboardRideOverview($service: BookingRideServiceFilterInput!) {
          dashboardRideOverview(service: $service) {
            twoSeatersPartnerCount
            fourSeatersPartnerCount
            sixSeatersPartnerCount

            twoSeatersBookingCount
            fourSeatersBookingCount
            sixSeatersBookingCount

            totalTwoSeatersEarnings
            totalFourSeatersEarnings
            totalSixSeatersEarnings
            
            totalTwoSeaterssComissions
            totalFourSeaterssComissions
            totalSixSeaterssComissions
          }
        }           
        `,
        variables: {
          service: service
        }
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['dashboardRideOverview']){
            const overview = res.data['dashboardRideOverview'];
            // Update direct mappings
            Object.keys(this.rideOverview).forEach((key) => {
              this.rideOverview[key] = overview[key];
            });

            console.log(this.rideOverview);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }
}