<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Partners Earnings</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Partners Earnings</h2>
                </div>
                <hr>
                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Partners with Commission</small>
                                    <h4>{{ this.partnerSumamry.totalPartnerCommisions }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/commission.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Earnings</small>
                                    <h4>{{ this.partnerSumamry.totalEarnings | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Payout</small>
                                    <h4>{{ this.partnerSumamry.totalPayout | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/payout.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="tools">
                        <div class="list-title">
                            <h4>List of Partners</h4>
                        </div>
                        <div class="search mg-right-auto">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default">Download</button>
                        </div>
                    </div>
                    <div class="list table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Partner ID</th>
                                    <th>Name</th>
                                    <!-- <th>Date</th>
                                    <th>Time of Submission</th> -->
                                    <th>Vehicle Type</th>
                                    <th>Earnings</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rider of this.currentSelectedPageList">
                                    <td>{{ rider.membershipId }}</td>
                                    <td>{{ rider.firstName + ' ' + rider.middleName + ' ' + rider.lastName}}</td>
                                    <td>{{ rider.vehicleType }}</td>
                                    <td>{{ rider.totalEarnings | currency : 'PHP' }}</td>
                                    <!-- <td>
                                        <span [ngClass]="(rider.isApprove) ? 'stat stat-active' : 'stat stat-pending'">{{
                                            (rider.isApprove) ? 'Verified' :
                                            'For Verification' }}</span>
                                    </td> -->
                                    <td><a (click)="viewDetails(rider)" style="cursor: pointer;">View</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <!-- <ol>
                                                                    <li *ngFor="let page of this.riderPages" style="cursor: pointer;" (click)="setPageSelected(page)"><a>{{ page
                                                                            }}</a></li>
                                                        
                                                                </ol> -->
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #showDetailComponent>
    <div class="main-holder">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><a (click)="this.returnToList()" style="cursor:pointer;">Partners Earnings</a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Partner Earnings Details</span></li>
                    </ol>
                </div>
                <div class="profile-holder">
                    <div class="profile">
                        <div class="pfp">
                            <img src="../../../../assets/okito/images/avatar.png">
                        </div>
                        <div class="profile-info">
                            <h4>{{ this.detailViewed['firstName'] + ' ' + this.detailViewed['middleName'] + ' ' + this.detailViewed['lastName'] }}
                            </h4>
                            <div class="tags">
                                <!-- <span class="stat stat-active">Active</span> -->
                                <span class="stat-icon"><img src="../../../../assets/okito/images/badge/vehicle.svg">{{
                                    this.detailViewed['vehicleType'] }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile-actions">
                        <!-- <button class="btn btn-sm btn-default">Edit Partner</button> -->
                    </div>
                </div>
                <div class="list-tabs">
                    <ul>
                        <li [ngClass]="{'active': this.partnerEarningTabs['orders']}"><a (click)="showTabContents('orders')" style="cursor: pointer;">Orders</a></li>
                        <li [ngClass]="{'active': this.partnerEarningTabs['incentive']}"><a (click)="showTabContents('incentive')" style="cursor: pointer;">Incentives</a></li>
                        <li [ngClass]="{'active': this.partnerEarningTabs['bonus']}"><a (click)="showTabContents('bonus')" style="cursor: pointer;">Bonus</a></li>
                        <li [ngClass]="{'active': this.partnerEarningTabs['tip']}"><a (click)="showTabContents('tip')" style="cursor: pointer;">Tip</a></li>
                        <li [ngClass]="{'active': this.partnerEarningTabs['withdraw']}"><a (click)="showTabContents('withdraw')" style="cursor: pointer;">Withdraw</a></li>
                    </ul>
                </div>
                <div class="list-holder">
                    <div class="list table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Earnings</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Deliver to One World Place</td>
                                    <td>Apr 17, 2024</td>
                                    <td><span class="stat stat-active">+P90.00</span></td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td>Deliver to San Joaquin</td>
                                    <td>Apr 17, 2024</td>
                                    <td><span class="stat stat-active">+P70.00</span></td>
                                    <td><a href="#">View</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </ng-template>
</div>