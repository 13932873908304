import { Component } from '@angular/core';

@Component({
  selector: 'app-success-msg',
  standalone: true,
  imports: [],
  templateUrl: './success-msg.component.html',
  styleUrl: './success-msg.component.css'
})
export class SuccessMsgComponent {

}
