import { Component } from '@angular/core';

@Component({
  selector: 'app-login-forgotpass',
  standalone: true,
  imports: [],
  templateUrl: './login-forgotpass.component.html',
  styleUrl: './login-forgotpass.component.css'
})

export class LoginForgotpassComponent {

}
