<div class="wrap">
    <header>
        <div class="logo">
            <a ><img src="../../../../assets/okito/images/okito-logo.svg"></a>
        </div>
        <div class="admin">
            <a ><img src="../../../../assets/okito/images/pfp.png"></a>
        </div>
    </header>

    <div class="main-holder">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
            <div class="bread">
                <ol>
                    <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                    <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                    <li><span>Top Ups</span></li>
                </ol>
            </div>
                <div class="content-title title-row">
                    <h2>Top-Ups</h2>
                    <div class="actions">
                        <button class="btn btn-sm btn-default" data-bs-toggle="modal"
                            data-bs-target="#create">Download</button>
                    </div>
                </div>
                <hr>
                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Top-Up Balance</small>
                                    <h4>{{ this.topupOverview['currentTopUpBalance'] | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Top-Up</small>
                                    <h4>{{ this.topupOverview['totalTopUpBalance'] | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/topup.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Partner Top-Up</small>
                                    <h4>{{ this.topupOverview['totalPartnerTopUpBalance'] | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/partner.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Top-Up Withdrawal</small>
                                    <h4>{{ this.topupOverview['totalConvertionTopUpBalance'] | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/payout.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Top-Up from Others</small>
                                    <h4>{{ this.topupOverview['totalOthersTopUpBalance'] | currency:'PHP' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/others.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="tools">
                        <div class="tools-grp">
                            <div class="search" style="width: 20em !important;">
                                <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                <input (input)="searchTopUpData($event)" [(ngModel)]="this.searchKeywordTopup" type="text" class="text-search"
                                    placeholder="Search">
                            </div>
                        </div>
                        <!-- <div class="pager">
                            <ol>
                                <li [ngClass]="{'active': dayViewtopUps['days_7']}"><a (click)="fetchWTopUpsByDateRange(7)" style="cursor:pointer;">Past
                                        7 Days</a></li>
                                <li [ngClass]="{'active': dayViewtopUps['days_30']}"><a (click)="fetchWTopUpsByDateRange(30)"
                                        style="cursor:pointer;">Past 30 Days</a></li>
                            </ol>
                        </div> -->
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Partner Code</th>
                                    <th>Partner Name</th>
                                    <th>Partner's Mobile Number</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Top-up Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageListTopups.length != 0; else noRecordsMain">
                                    <tr *ngFor="let topup of this.currentSelectedPageListTopups">
                                        <td>{{ topup.partnerTopupRequestId }}</td>
                                        <td>{{ topup.partner.partnerId }}</td>
                                        <td>{{ topup.partner.firstName }} {{ topup.partner.lastName }}</td>
                                        <td>{{ topup.partner.mobile }}</td>
                                        <td>{{ topup.dateCreated }} </td>
                                        <td>{{ topup.amount | currency:'PHP' }}</td>
                                        <td>{{ topup.source }}</td>
                                        <td><a (click)="viewTopupDetails(topup, topupDetails)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <div class="pager">
                                <button (click)="nextPrevTopups('prev')" [disabled]="this.disableActionBtnsTopups.prev"><img
                                        src="../../../../assets/okito/images/icons/prev.svg"></button>
                                <button (click)="nextPrevTopups('next')" [disabled]="this.disableActionBtnsTopups.next"><img
                                        src="../../../../assets/okito/images/icons/next.svg"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #topupDetails let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                <small>{{ this.topupdetailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A'
                    }}</small>
            </h5>
            <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <div class="booking-holder">
                <h5>Top Up Details</h5>
                <div class="details-grp">
                    <label>Transaction ID</label>
                    <span>{{ this.topupdetailViewed['partnerTopupRequestId'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Mobile Number</label>
                    <span>{{ this.topupdetailViewed['partner']['mobile'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Email Address</label>
                    <span>{{ this.topupdetailViewed['partner']['email'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Name</label>
                    <span>{{ this.topupdetailViewed['partner']['firstName'] + ' ' +
                        this.topupdetailViewed['partner']['lastName']}}</span>
                </div>
                <div class="details-grp">
                    <label>Vehicle Type</label>
                    <span>{{ this.topupdetailViewed['partner']['vehicleType'] }}</span>
                </div>
                <!-- <div class="details-grp">
                        <label>Service Type</label>
                        <span>Ok! Express, Ok! MotoTaxi</span>
                    </div> -->
                <div class="details-grp">
                    <label>Amount</label>
                    <span>{{ this.topupdetailViewed['amount'] | currency:'PHP' }}</span>
                </div>
                <!-- <div class="details-grp">
                        <label>Top-Up Type</label>
                        <span>Partner</span>
                    </div> -->
                <div class="details-grp">
                    <label>Source</label>
                    <span>{{ this.topupdetailViewed['source'] }}</span>
                </div>
            </div>
    
        </div>
    </ng-template>

</div>