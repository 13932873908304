import { Component, TemplateRef, inject } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { NavComponent } from "../navigation/nav.component";
import { DashboardService } from '@services/dashboard.service';
import { LocalService } from '@services/local.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformVehicle } from '@interfaces/models.interface';

@Component({
    selector: 'app-dashboard-vehicle',
    standalone: true,
    templateUrl: './dashboard-vehicle.component.html',
    styleUrl: './dashboard-vehicle.component.css',
    imports: [HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule]
})
export class DashboardVehicleComponent {

  constructor(
        private dasher: DashboardService,
        private localservice: LocalService,
    ) { }

  imageSrc?: string | ArrayBuffer | null; 
  private modalService = inject(NgbModal);
  public vehicleList: PlatformVehicle[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: PlatformVehicle[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public vehiclePages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public detailViewed: Record<string, any> = {};
  public showDetails: boolean = false;
  public vehicleNew: Record<string, any> = {
    "category": "",
    "description": "",
    "basePrice": 0,
    "priceKm": 0,
    "dimension": "",
    "weight": "",
    "icon": "",
    "status": 1
  }

  vPhoto: File | null = null;
  public enableEdit: boolean = false;

  vehicleTabs: { [key: string]: boolean } = {
    'express': true,
    'mototaxi': false,
    'ride': false
  };

  setTabPanelActive(tabName: string) {
    Object.keys(this.vehicleTabs).forEach(key => {
      this.vehicleTabs[key] = false;
    });
    this.vehicleTabs[tabName] = true;
  }

  ngOnInit(): void {
    this.fetchVehicles();
  }

  fetchVehicles(action: string = ''){
      var fromPage = 1
      if(action != ''){
          fromPage = this.currentPage;
          switch(action){
              case 'next':
                  this.currentPage++
              break;
    
              case 'prev':
                  if(this.currentPage != 1){
                      this.currentPage--
                  }//end if
              break;
          }//end swithc    
      }//end if
      
      this.dasher.tryFetchAvailVehicles(this.currentPage).subscribe((res: any) => {
        if(res.length != 0){
            this.vehicleList = [];
            res.forEach((ad_instance : any) => {
                let i = ad_instance;
                this.vehicleList.push(i);
            });

            this.currentSelectedPageList = this.vehicleList;
        }else{
            this.currentPage = fromPage;
        }//end if
      });
  }//end fn
  

  showCreateAdForm(modal: TemplateRef<any>){
    this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
  }//end fn

  nextPrev(action: string){
    switch(action){
        case 'next':
            this.fetchVehicles('next');
        break;

        case 'prev':
            this.fetchVehicles('prev');
        break;
    }//end swithc
  }//end fn

  searchData(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchKeyword = inputElement.value;
  
    if (this.searchKeyword.trim() === '') {
        console.log('Input is empty');
        this.currentSelectedPageList = this.vehicleList
    }else{
        this.currentSelectedPageList =  this.vehicleList.filter(object =>
            Object.values(object).some(value => 
                (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
            )
        );
    }//end if
  }//end nfn

  createNewVehicle(confirmModal: TemplateRef <any>){  
    var errors: number = 0;
    Object.keys(this.vehicleNew).forEach((x) => {
      if(x != 'icon'){
        if(this.vehicleNew[x] == ''){
          errors ++;
        }//end if
      }//end if
    });
  
    if(errors != 0){
      alert('Please enter all required fields');
    }else{
      this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
    }//end if
  }//end fn

  proceedCreateVehicle(successModal: TemplateRef <any>){  
    const formData:any = new FormData();      
    if (this.vPhoto) {
      formData.append('file', this.vPhoto, this.vPhoto.name);
    }
    
    console.log(this.vehicleNew);

    this.dasher.uploadPhoto(formData, 'vehicle_photo').subscribe((res: any) => {
      this.vehicleNew['icon'] = res.photo;
      this.dasher.createVehicle(this.vehicleNew).subscribe((res: any) => {
        if(res.length != 0){
          this.currentPage = 1;
          this.vehicleList = [res, ...this.vehicleList];
          this.maxPages = this.localservice.getTotalPages(this.vehicleList, this.pageSize);
          this.vehiclePages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
          this.currentSelectedPageList = this.localservice.paginateData(this.vehicleList, this.currentPage, this.pageSize)
          
          if(this.maxPages == 1){
              this.disableActionBtns.next = true;
              this.disableActionBtns.prev = true;
          }else{
              this.disableActionBtns.next = false;
              this.disableActionBtns.prev = true;
          }//end if

          this.modalService.dismissAll();
          Object.keys(this.vehicleNew).forEach((x) => {
            this.vehicleNew[x] = '';
          });
          this.imageSrc = null;
          this.vPhoto = null;
          this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
      });
    });
  }//end fn

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader(); 
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(file);
      this.vPhoto = file;
    }//end
  }//end fn

  closeAllModals(){
    this.modalService.dismissAll();
  }//end fn

  editVehicle(message: string) {
    alert(message);
  }
}
