import { Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { AuthStore } from '@store/auth.store';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css'
})
export class NavComponent {
  constructor(
    private router: Router,
    private AuthService: AuthService, 
    private activatedRoute: ActivatedRoute,
    private authStore: AuthStore
  ) {
  }

  openParentMenu: Record<string, any> = {
    users: false,
    command_center: false,
    transactions: false,
    disc_rewards: false,
    cms: false,
    art_notifs: false
  }

  navArray: Record<string, any> = {
    home: false,
    users: false,
    partners: false,
    partner_earnings: false,
    transactions: false,
    admins: false,
    ads_m: false,
    news_and_events_m: false,
    notification_m: false,
    coupons_m: false,
    pricing_m: false,
    withdrawal_cms_m: false,
    rewards_cms_m: false,
    vehicle_cms_m: false,
    location_cms_m: false,
    m_top_ups: false,
    comcenter_overview: false,
    comcenter_express: false,
    comcenter_ride: false,
    comcenter_motaxi: false,
  };

  routeName: string = '';

  ngOnInit(): void {
     // Access the route's data property
     this.activatedRoute.data.subscribe((data) => {
      this.routeName = data['name'];
      
      Object.keys(this.navArray).forEach((x) => {
        this.navArray[x] = false;
      });

      this.navArray[this.routeName] = true;
    });
  }

  navigateMenu(url: string, newTab: boolean = false) {
    if (newTab) {
        window.open(`/${url}`, '_blank');
    } else {
        this.router.navigate([`/${url}`]);
    }
  }

  openParentMenuFn(menu: string){
    Object.keys(this.openParentMenu).forEach((x) => {
      this.openParentMenu[x] = false;
    });
    
    this.openParentMenu[menu] = true;
  }
  accountLogout(){
    this.authStore.logout().subscribe(() => {
      this.router.navigate(['/admin/login']);
    });
  }
}
