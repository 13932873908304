import { Component, NgModule, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { decrement, increment, reset } from '@actions/counter.actions';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { CookieService } from 'ngx-cookie-service';

import { loginForm, forgotPasswordForm } from '@interfaces/auth.interfaces';
import { ServerResponse } from '@interfaces/general.interface';
import { environment } from '@envs/environment';

//SUB COMPONENTS
import { LoginVerifyotpComponent } from '@components/authentication/login-verifyotp/login-verifyotp.component';
import { LoginForgotpassComponent } from '@components/authentication/login-forgotpass/login-forgotpass.component';
import { SuccessMsgComponent } from '@components/modals/success-msg/success-msg.component';
import { ToastrService } from 'ngx-toastr';
import { Server } from 'http';
import { Apollo, gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports:      [ 
    CommonModule, 
    LoginForgotpassComponent, 
    LoginVerifyotpComponent, 
    SuccessMsgComponent, 
    FormsModule,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.css',
})

export class LoginFormComponent {
  token: string | null = null;
  //###################################
  //VARIABLES
  //###################################
  public formtoShow: string = 'login';
  public user_email: string = '';
  public otp_code: string = '';
  public isPasswordShown: any = {
    'txt-pass-1': 'password',
    'txt-pass-2': 'password',
    'txt-pass-3': 'password',
    'txt-pass-4': 'password',
  };
  public userForm: loginForm = {
    email: '', //dev.rtabora@gmail.com
    password: '', //jaoski
  };

  public forgot_passemail: string = '';
  
  //VARIABLES FOR STORE SAMPLE
  private store = inject(Store);
  count$?: Observable<number>

  //###################################
  //CONSTRUCTOR
  //###################################
  constructor(
    private AuthService: AuthService, 
    private toastr: ToastrService,
    private cooker: CookieService,
    //private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) {
    this.count$ = this.store.select('counter');
    console.log(environment.apiUrl);
  }

  //###################################
  //FUNCTIONS / METHODS
  //###################################
  attempLogin(loginForm: loginForm) {
    this.apollo.mutate({
      mutation: gql`              
        mutation adminLogin($authenticationInput:AuthenticationInput!) {
          adminLogin(authenticationInput:$authenticationInput) {
            token
          }
        }
      `,
      variables:{
        "authenticationInput": loginForm
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;
        if(res != null){
          const token: string = res.adminLogin.token;
          
          // Update store
          this.authStore.setToken(token);
          this.authStore.setOtpVerified(false);
          
          this.toastr.success(res.msg, 'LOGIN SUCCESSFUL - OTP CONFIRM', {
            closeButton: true,
            timeOut: 3000
          });
          this.formtoShow = "verify_otp";
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn
  
  togglePasswordShow(indx: any){
    this.isPasswordShown[indx] = this.isPasswordShown[indx] == 'password' ? this.isPasswordShown[indx] = 'text' : this.isPasswordShown[indx] = 'password';
  }//end fn

  verifyOTP(){
    this.apollo.mutate({
      mutation: gql`              
      mutation verifyAdminOtp($otp:String!) {
        verifyAdminOtp(otp:$otp) {
          message
        }
      }`,
      variables:{
        "otp": this.otp_code.toString()
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;

        if(res != null) {
          let otpNameValidotp = environment.otpValidVar;
          this.cooker.set(otpNameValidotp, '1');
          
          // Update store first
          this.authStore.setOtpVerified(true);
          
          this.toastr.success(res.msg, 'LOGIN SUCCESSFUL - OTP CONFIRM', {
            closeButton: true,
            timeOut: 3000
          });

          // Navigate after a brief delay to ensure state is updated
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 100);
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn

  resetPassword(){
    this.apollo.mutate({
      mutation: gql`              
      mutation requestAdminForgotPassword($email:String!){
        requestAdminForgotPassword(email:$email) {
          message
        }
      }`,
      variables: {
        "email": this.forgot_passemail
      }
    }).subscribe({
      next: (result) => {
        this.forgot_passemail = '';
        let res:any = result.data;
        if(res != null){
          let msg: string = res.requestAdminForgotPassword.message;
          this.toastr.success(msg, 'RESET PASSWORD SUCCESSFULL', {
            closeButton: true,
            timeOut: 3000
          });
          this.formtoShow = "login";
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn

  /* updatePassword(){
    if(this.forgot_passform.new_password != this.forgot_passform.confirm_password){
      this.toastr.error('New password and Confirm Password does not match', 'ERROR', {
        closeButton: true,
        timeOut: 3000
      });
    }else{
      //ADD REG EX VERIFICATION
      this.AuthService.tryUpdatingPassword(this.forgot_passform).subscribe((res: ServerResponse) => {
        if(res.status){
          this.toastr.success(res.msg, 'PASSWORD RESET SUCCESSFUL', {
            closeButton: true,
            timeOut: 3000
          });

         this.forgot_passform.email = '';
         this.forgot_passform.confirm_password = '';
         this.forgot_passform.temp_password = '';
         this.forgot_passform.confirm_password = '';

          this.formtoShow = "password_reset_success";
        }else{
          this.toastr.error(res.msg, 'ERROR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      })
    }//end if
  } */

  resentEmailVlink(){
    this.AuthService.tryResendEmailValidationLink(this.user_email).subscribe((res: ServerResponse) => {
      if(res.status){
        this.toastr.success(res.msg, 'VERIFY EMAIL', {
          closeButton: true,
          timeOut: 3000
        });
      }else{
        this.toastr.error(res.msg, 'ERROR RESEND VERIFICATION LINK', {
          closeButton: true,
          timeOut: 3000
        });
      }//end if
    });
  }//end fn

  resendAuthOTP(){
    this.apollo.mutate({
      mutation: gql`                    
      mutation resendAdminOtp {
        resendAdminOtp {
          message
        }
      }`,
    }).subscribe({
      next: (result) => {
        let res:any = result.data;
        if(res != null){
          let msg: string = res.resendAdminOtp.message;
          this.toastr.success(msg, 'OTP RESENT SUCCESSFULL', {
            closeButton: true,
            timeOut: 3000
          });
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
    /* this.AuthService.tryResendAuthOTP(this.userForm.email).subscribe((res: ServerResponse) => {
      if(res.status){
        this.toastr.success(res.msg, 'OTP', {
          closeButton: true,
          timeOut: 3000
        });
      }else{
        this.toastr.error(res.msg, 'ERROR RESEND OTP', {
          closeButton: true,
          timeOut: 3000
        });
      }//end if
    }); */
  }//end fn

  increment(){
    this.store.dispatch(increment());
  }

  decrement(){
    this.store.dispatch(decrement());
  }

  reset(){
    this.store.dispatch(reset());
  }

  ngOnInit(): void {
    // Subscribe to the token observable
    /* this.authStore.isAuthenticated$.subscribe(token => {;
      console.log('Tokenz:', token);
    }); */
  }

  //###################################
  //LIFE CYCLES
  //###################################
  /* ngOnInit(): void {
    console.log('ngOnInit - Component initialized');
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy - Component destroyed');
  }

  ngOnChanges(): void {
    console.log('ngOnChanges - Input properties changed');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit - Component view initialized');
  }

  ngAfterViewChecked(): void {
    console.log('ngAfterViewChecked - Component view checked');
  }

  ngAfterContentInit(): void {
    console.log('ngAfterContentInit - Component content initialized');
  }

  ngAfterContentChecked(): void {

  } */
}
