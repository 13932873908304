import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@envs/environment';
import { Router } from '@angular/router';

export interface User {
  id?: string;
  email?: string;
  name?: string;
  // Add other user properties as needed
}

export interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
  otpVerified: boolean;
  user: User | null;
}

const initialState: AuthState = {
  token: null,
  isAuthenticated: false,
  otpVerified: false,
  user: null,
};

@Injectable({
  providedIn: 'root'
})
export class AuthStore {
  private state = new BehaviorSubject<AuthState>(this.getInitialState());
  
  // Update isAuthenticated$ to derive from state
  isAuthenticated$ = this.state.pipe(
    map(state => ({
      isAuthenticated: state.isAuthenticated,
      otpVerified: state.otpVerified
    }))
  );

  readonly token$ = this.state.pipe(map(state => state.token));
  readonly otpVerified$ = this.state.pipe(map(state => state.otpVerified));
  readonly user$ = this.state.pipe(map(state => state.user));
  
  // Get current state snapshot
  get currentState(): AuthState {
    return this.state.getValue();
  }

  constructor(private router: Router) {
    // Subscribe to state changes to persist in localStorage
    this.state.subscribe(state => {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('auth-storage', JSON.stringify(state));
      }
    });
  }

  private getInitialState(): AuthState {
    if (typeof localStorage !== 'undefined') {
      const savedState = localStorage.getItem('auth-storage');
      return savedState ? JSON.parse(savedState) : initialState;
    }
    return initialState;
  }

  setToken(token: string): void {
    const newState = {
      ...this.currentState,
      token,
      isAuthenticated: !!token
    };
    this.state.next(newState);
  }

  setOtpVerified(verified: boolean): Observable<boolean> {
    const newState = {
      ...this.currentState,
      otpVerified: verified
    };
    this.state.next(newState);
    
    return new Observable<boolean>(observer => {
      observer.next(true);
      observer.complete();
    });
  }

  setUser(user: User): void {
    this.state.next({
      ...this.currentState,
      user
    });
  }

  logout(): Observable<boolean> {
    console.log('logout');

    // Set authentication and OTP verification to false
    this.state.next({
      ...this.currentState,
      isAuthenticated: false,
      otpVerified: false
    });

    // Clear cookies
    document.cookie = `${environment.tokenName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `${environment.otpValidVar}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    
    // Reset state
    this.state.next(initialState);
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('auth-storage');
    }

    // Return an Observable instead of boolean
    return new Observable<boolean>(observer => {
      observer.next(true);
      observer.complete();
    });
  }

  // Helper method to select specific state slice
  select<K extends keyof AuthState>(key: K): Observable<AuthState[K]> {
    return this.state.pipe(map(state => state[key]));
  }
}