<div class="wrap">
    <div class="login-grid">
        <div class="logo">
            <img src="../../../../assets/okito/images/okito-logo.svg">
        </div>

        <!-- LOGIN FORM -->
        <div class="login" *ngIf="formtoShow == 'login'">
            <form class="user" (ngSubmit)="attempLogin(this.userForm)">
            <h3>Sign In</h3>
            <div class="login-grp"> <!-- for error -->
                <label>Email Address</label>
                <div class="input-icon-grp">
                    <img src="../../../../assets/okito/images/icons/email.svg" class="input-icon">
                    <input [(ngModel)]="this.userForm.email" type="email" name="email" placeholder="Email Address" class="input-text">
                </div>
            </div>

            <div class="login-grp">
                <div class="label-grp">
                    <label>Password</label>
                    <span (click)="formtoShow = 'forgot_password'" style="color:red;cursor: pointer;">Forgot your Password?</span>
                </div>
                <div class="input-icon-grp">
                    <img src="../../../../assets/okito/images/icons/password.svg" class="input-icon">
                    <input [(ngModel)]="this.userForm.password" [type]="isPasswordShown['txt-pass-1']" name="password" placeholder="Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-1')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <div class="login-grp">
                <button type="submit">Login</button>
            </div>
            
            <!-- <div class="alert alert-danger" role="alert">
                Error: Lorem ipsum dolor sit
            </div> -->
            </form>
        </div>


        <!-- OTP VERIFY FORM -->
        <div class="login" *ngIf="formtoShow == 'verify_otp'">
            <h4>Verify</h4>
            <p>
                The verification code (OTP) was sent to your email address. Please enter the code.
                <!-- <br>
                <div *ngIf="this.token$ | async as token">
                    Token: {{ token }}
                </div> -->
            </p>
            <div class="login-grp">
                <input [(ngModel)]="this.otp_code" type="text" name="otp_code" placeholder="Enter OTP" class="input-text">
            </div>
            <div class="login-grp">
                <button (click)="verifyOTP()">Verify OTP</button>
            </div>
            <span class="login-link">Didn't receive an OTP? <span style="cursor: pointer;color:red;"
                    (click)="resendAuthOTP()">Resend</span></span>
        </div>


        <!-- FIRST TIME LOGIN -->
        <div class="login" *ngIf="formtoShow == 'verify_email'">
            <div class="update-success">
                <img src="../../../../assets/okito/images/verify.svg">
                <h2>Verify your email</h2>
                <p>We've sent a link to your email address: <span>{{ this.user_email }}</span></p>
                <div class="login-grp">
                    <button (click)="formtoShow = 'login'" >Back to Login</button>
                </div>
                <span class="login-link">Didn't receive an email? <span style="cursor: pointer;color:red;" (click)="resentEmailVlink()" >Resend</span></span>
            </div>
        </div>

        <!-- PASSWORD CHANGE SUCCESS -->
        <div class="login" *ngIf="formtoShow == 'password_reset_success'">
            <div class="update-success">
                <img src="../../../../assets/okito/images/password-success.svg">
                <h2 style="margin-bottom: 1em;">Password reset successful</h2>
                <span>Your password is updated. Login again using new password.</span>
                <div class="login-grp" style="margin-top: 1.5em;">
                    <button (click)="formtoShow = 'login'" >Proceed to login</button>
                </div>
            </div>
        </div>

        <!-- ENTER EMAIL FORGOT PASSWORD-->
        <div class="login" *ngIf="formtoShow == 'forgot_password'">
            <h3>Reset Password</h3>
            <div class="login-grp">
                <label>Enter your Email</label>
                <div class="input-text-grp">
                    <input  [(ngModel)]="this.forgot_passemail" type="text" name="" placeholder="Enter your email" class="input-text">
                </div>
            </div>
            <p>
                We will be reset your password and send it to your email
            </p>
            <div class="login-grp">
                <button (click)="resetPassword()">Submit</button>
            </div>

            <div style="text-align: center; margin-top: 2em;">
                <span (click)="formtoShow = 'login'" style="color:red;cursor: pointer;">Return to Login</span>
            </div>
        </div>


        <!-- CHANGE PASSWORD -->
        <!-- <div class="login" *ngIf="formtoShow == 'change_password'">
            <h3>Change Password</h3>
            <div class="login-grp">
                <label>Temporary Password</label>
                <div class="input-text-grp">
                    <input  [(ngModel)]="this.forgot_passform.temp_password" [type]="isPasswordShown['txt-pass-2']" name="" placeholder="Enter Temporary Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-2')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <div class="login-grp">
                <label>New Password</label>
                <div class="input-text-grp">
                    <input  [(ngModel)]="this.forgot_passform.new_password" [type]="isPasswordShown['txt-pass-3']" name="" placeholder="Enter Temporary Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-3')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <div class="login-grp">
                <label>Confirm Password</label>
                <div class="input-text-grp">
                    <input [(ngModel)]="this.forgot_passform.confirm_password" [type]="isPasswordShown['txt-pass-4']" name="" placeholder="Enter Temporary Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-4')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <p>
                Password must be 8-16 alphanumeric characters long (without spaces), and with at least 1 number
            </p>
            <div class="login-grp">
                <button (click)="updatePassword()">Change Password</button>
            </div>

            <div style="text-align: center; margin-top: 2em;">
                <span (click)="formtoShow = 'login'" style="color:red;cursor: pointer;">Return to Login</span>
            </div>
        </div> -->
    </div>
</div>
