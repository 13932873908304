import { Routes } from '@angular/router';

import { DashboardHomeComponent } from '@components/dashboard/dashboard-home/dashboard-home.component';
import { DashboardAdminsComponent } from '@components/dashboard/dashboard-admins/dashboard-admins.component';
import { DashboardUsersComponent } from '@components/dashboard/dashboard-users/dashboard-users.component';
import { DashboardRidersComponent } from '@components/dashboard/dashboard-riders/dashboard-riders.component';
import { DashboardNewsEventsComponent } from '@components/dashboard/dashboard-news-events/dashboard-news-events.component';
import { DashboardAdsManageComponent } from '@components/dashboard/dashboard-ads-manage/dashboard-ads-manage.component';
import { DashboardNotificationComponent } from '@components/dashboard/dashboard-notification/dashboard-notification.component';
import { DashboardRiderEarningsComponent } from '@components/dashboard/dashboard-rider-earnings/dashboard-rider-earnings.component';
import { DashboardCouponManagementComponent } from '@components/dashboard/dashboard-coupon-management/dashboard-coupon-management.component';
import { DashboardPaymentManagementComponent } from '@components/dashboard/dashboard-payment-management/dashboard-payment-management.component';
import { DashboardPricingManagementComponent } from '@components/dashboard/dashboard-pricing-management/dashboard-pricing-management.component';
import { DashboardRewardsComponent } from '@components/dashboard/dashboard-rewards/dashboard-rewards.component';
//import { DashboardWithdrawalComponent } from '@components/dashboard/dashboard-withdrawal/dashboard-withdrawal.component';
import { DashboardLocationComponent } from '@components/dashboard/dashboard-location/dashboard-location.component';
import { DashboardVehicleComponent } from '@components/dashboard/dashboard-vehicle/dashboard-vehicle.component';

import { LoginFormComponent } from '@components/authentication/login-form/login-form.component';

//import { authenticatorGuard, loggeduserGuard } from '@guards/authenticator.guard';
import { VerifyAdminAccComponent } from '@components/authentication/verify-admin-acc/verify-admin-acc.component';
import { NotFoundComponent } from '@components/wildcards/not-found/not-found.component';
import { DashboardTransactionsComponent } from '@components/dashboard/dashboard-transactions/dashboard-transactions.component';
import { DashboardTopupComponent } from '@components/dashboard/dashboard-topup/dashboard-topup.component';
import { AuthGuard } from '@guards/auth.guard';
import { loggeduserGuard } from '@guards/authenticator.guard';  // Keep only loggeduserGuard
import { LoggedGuard } from '@guards/logged.guard';
import { DashboardComcenterOverviewComponent } from '@components/dashboard/dashboard-comcenter-overview/dashboard-comcenter-overview.component';
import { DashboardComcenterExpresspageComponent } from '@components/dashboard/dashboard-comcenter-expresspage/dashboard-comcenter-expresspage.component';
import { DashboardComcenterRideComponent } from '@components/dashboard/dashboard-comcenter-ride/dashboard-comcenter-ride.component';
import { DashboardComcenterMotaxiComponent } from '@components/dashboard/dashboard-comcenter-motaxi/dashboard-comcenter-motaxi.component';


export const routes: Routes = [
    { path: '', component: DashboardHomeComponent, canActivate: [AuthGuard], data: { name: 'home' }},
    { path: 'admin/users', component: DashboardUsersComponent, canActivate: [AuthGuard], data: { name: 'users' } },
    { path: 'admin/accounts', component: DashboardAdminsComponent, canActivate: [AuthGuard], data: { name: 'admins' } },
    { path: 'admin/riders', component: DashboardRidersComponent, canActivate: [AuthGuard], data: { name: 'partners' } },
    { path: 'admin/partner-earnings', component: DashboardRiderEarningsComponent, canActivate: [AuthGuard], data: { name: 'partner_earnings' } },
    { path: 'admin/transactions', component: DashboardTransactionsComponent, canActivate: [AuthGuard], data: { name: 'transactions' } },
    { path: 'admin/ads-management', component: DashboardAdsManageComponent, canActivate: [AuthGuard], data: { name: 'ads_m' } },
    { path: 'admin/newsevents-management', component: DashboardNewsEventsComponent, canActivate: [AuthGuard], data: { name: 'news_and_events_m' } },
    { path: 'admin/notification-management', component: DashboardNotificationComponent, canActivate: [AuthGuard], data: { name: 'notification_m' } },
    { path: 'admin/coupons-management', component: DashboardCouponManagementComponent, canActivate: [AuthGuard], data: { name: 'coupons_m' } },
    { path: 'admin/pricing-management', component: DashboardPricingManagementComponent, canActivate: [AuthGuard], data: { name: 'pricing_m' } },
    { path: 'admin/withdrawal-cms', component: DashboardPaymentManagementComponent, canActivate: [AuthGuard], data: { name: 'withdrawal_cms_m' } },
    { path: 'admin/rewards-cms', component: DashboardRewardsComponent, canActivate: [AuthGuard], data: { name: 'rewards_cms_m' } },
    { path: 'admin/vehicle-cms', component: DashboardVehicleComponent, canActivate: [AuthGuard], data: { name: 'vehicle_cms_m' } },
    { path: 'admin/location-cms', component: DashboardLocationComponent, canActivate: [AuthGuard], data: { name: 'location_cms_m' } },
    { path: 'admin/topups', component: DashboardTopupComponent, canActivate: [AuthGuard], data: { name: 'm_top_ups' } },

    { path: 'admin/command-center/overview', component: DashboardComcenterOverviewComponent, canActivate: [AuthGuard], data: { name: 'comcenter_overview' } },
    { path: 'admin/command-center/express', component: DashboardComcenterExpresspageComponent, canActivate: [AuthGuard], data: { name: 'comcenter_express' } },
    { path: 'admin/command-center/okride', component: DashboardComcenterRideComponent, canActivate: [AuthGuard], data: { name: 'comcenter_ride' } },
    { path: 'admin/command-center/mototaxi', component: DashboardComcenterMotaxiComponent, canActivate: [AuthGuard], data: { name: 'comcenter_motaxi' } },

    { path: 'admin/login', component: LoginFormComponent, canActivate: []},
    { path: 'admin/verify/:status', component: VerifyAdminAccComponent},

    // Wildcard route for 404 error
    { path: '**', component: NotFoundComponent }
];