import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[AppCurrencyFormatterDirective]',
  standalone: true
})

export class AppCurrencyFormatterDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value: any) {
    this.el.value = value.replace(/₱\s?|(,*)/g, "");
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    let numberValue = parseFloat(value.replace(/₱\s?|(,*)/g, ""));
    if (isNaN(numberValue)) {
      this.el.value = '₱ 0.00';
    } else {
      this.el.value = '₱ ' + numberValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
}
